@if (event) {
  <div
    class="event-content"
    [class.brand-colors]="!isEventThemeColor"
    [class.tickets-view]="ticketsView"
    [class.blurred-fields]='blurredFields'
  >
    <div
      class="event-name primary-font"
      [ngStyle]="{
        color: bodyColor
      }"
      [style.font-weight]="
        font?.primaryFontWeight ? font?.primaryFontWeight : null
      "
    >
      {{ event.name }}
    </div>
    <div
      class="event-date-loc-info body-font"
      [ngStyle]="{
        color: bodyColor
      }"
    >
      @if (event.startDate) {
        <div class="event-date-cont event-info-with-icon">
          <div class='date-wrapper'>
            <app-date-card
              [brandColors]='!isEventThemeColor'
              [date]="event.startDate"
              [monthOnly]='true'
              class="event-date icon"
            ></app-date-card>
          </div>

          <div>
            <div class="event-info-primary">
              {{ event.getEventInfoDateLine1(currentLang) }}
            </div>
            <div class="event-info-sub">
              @if (event.endDate && !event.endsOnSameDate()) {
                {{ "APP.DATE_TO" | translate }}
              }
              {{ event.getEventInfoDateLine2(currentLang) }}
            </div>
          </div>
        </div>
      } @else {
        <a
          class="event-address event-date-cont event-info-with-icon"
          [ngStyle]="{
            color: bodyColor
          }"
          target="_blank"
        >
          <div class='icon'>
            <i class="far fa-calendar-day"></i>
          </div>
          <div>
            {{  "APP.EVENT.DATE_TBD" | translate }}
          </div>
        </a>
      }
      @if (event.hasAddress()) {
        @if(showMap && !isServer){
          <div class='address-with-map-wrapper' [matTooltip]='event.getAddressDisplayNamePrimary()'>
            <a
              class="event-address event-info-with-icon body-font"
              [ngStyle]="{
            color: bodyColor
          }"
              [href]="event.getGoogleMapsLink()"
              target="_blank"
            >
              <div class='icon'>
                <i class="far fa-map-marker-alt"></i>
              </div>
              <div>
                <div class="event-info-primary">
                  @if (event.getAddressDisplayNamePrimary().length > 20 && !isDesktop){
                    {{ event.getAddressDisplayNamePrimary().slice(0, 20) }}...
                  } @else if(event.getAddressDisplayNamePrimary().length > 35){
                    {{ event.getAddressDisplayNamePrimary().slice(0, 35) }}...
                  }@else{
                    {{ event.getAddressDisplayNamePrimary() }}
                  }
                </div>
                <div class="event-info-sub">
                  {{ event.getAddressDisplayNameSecondary() }}
                </div>
              </div>
            </a>
              <app-event-section-map class='section-map' [disableActions]='true'></app-event-section-map>
          </div>
        }@else{
          <a
            class="event-address event-info-with-icon"
            [ngStyle]="{
            color: bodyColor
          }"
            [href]="event.getGoogleMapsLink()"
            target="_blank"
          >
            <div class='icon'>
              <i class="fal fa-map-marker-alt"></i>
            </div>
            <div>
              <div class="event-info-primary">
                {{ event.getAddressDisplayNamePrimary() }}
              </div>
              <div class="event-info-sub">
                {{ event.getAddressDisplayNameSecondary() }}
              </div>
            </div>
          </a>
        }

      }@else if(showMap){
        <div class="address-with-map-wrapper">
          <a
            class="event-address event-info-with-icon"
            [ngStyle]="{
            color: bodyColor
          }"
            target="_blank"
          >
            <div class='icon'>
              <i class="far fa-map-marker-alt"></i>
            </div>
            <div>
              {{ "APP.EVENT.LOCATION_TBD" | translate }}
            </div>
          </a>
        </div>
      } @else {
        <a
          class="event-address event-info-with-icon"
          [ngStyle]="{
            color: bodyColor
          }"
          target="_blank"
        >
          <div class='icon'>
            <i class="far fa-map-marker-alt"></i>
          </div>
          <div>
            {{ "APP.EVENT.LOCATION_TBD" | translate }}
          </div>
        </a>
      }
    </div>

    @if (showHosts) {
      <div class="hosts-section">
        <i class="far fa-crown"></i>
        <app-event-hosts
          class='w-100'
          [scrollView]='blurredFields ? true : false'
          [event]="event"
          [isVotingView]="isVotingView"
          [avatarSize]="blurredFields ? 24 : 30"
          [avatarPlaceholderFontSize]="12"
        ></app-event-hosts>
      </div>
    }
    @if (showDescription) {
      @if (!event.type || event.type === EventType.PARTYPAGE) {
        <div
          class="body-text body-font"
          [innerHtml]="QuillUtils.replaceEmptyPTags(event.description) | safeHtml"
          [ngStyle]="{
            color: bodyColor
          }"
        ></div>
      } @else {
        <div
          class="body-text body-font"
          [innerHtml]="QuillUtils.replaceEmptyPTags(event.description) | safeHtml"
          [ngStyle]="{
            color: bodyColor
          }"
        ></div>
      }
    }
  </div>
}
