@if (event) {
  <div class="checkout-wrapper" [class.generic-wrapper]="isGeneric">
    @if (!isGeneric) {
      <div class="header">
        <div class="title">
          {{ "APP.EVENT_PAYMENT.THANK_YOU_FOR_YOUR_ORDER" | translate }}
        </div>
        <div class="description">
          {{ "APP.EVENT_PAYMENT.YOU_PARTICIPATE" | translate }}
        </div>
        @if (!disableClose) {
          <div class="close-button" (click)="close()">
            <i class="fal fa-times-circle"></i>
          </div>
        }
      </div>
    }

    <div class="content">
      @if (!isDesktop) {
        <div
          class="add-to-wallet-actions"
          [class.disabled]="ticketOrder?.isProcessing()"
        >
          <div class="google-wallet" (click)="addToWallet()">
            <img src="assets/add_to_google_wallet.svg" />
          </div>
          <div class="apple-wallet" (click)="addToWallet()">
            <img src="assets/add-to-apple-wallet.svg" />
          </div>
        </div>
        <div class="action">
          @if (ticketOrder?.isProcessing()) {
            <button class="btn btn-primary-outline processing-btn">
              <div class="loader"></div>
              {{ "APP.EVENT_PAYMENT.PAYMENT_PROCESSING" | translate }}
            </button>
          } @else {
            <button
              class="btn btn-primary-outline"
              (click)="downloadTicketPdf()"
            >
              {{ "APP.EVENT_PAYMENT.DOWNLOAD_TICKETS" | translate }}
            </button>
          }
        </div>
      }
      <div class="order">
        <div
          class="image"
          [ngStyle]="{
            'background-image': 'url(' + encodedMainImage() + ')',
            'background-color': visual?.backgroundColor
          }"
        >
          @if (event.isMainPictureVideo()) {
            <div class="background-video-cont">
              <video
                class="key-visual-1"
                [muted]="'muted'"
                autoplay
                playsinline
                loop
              >
                <source [src]="event.mainPictureUrl" type="video/mp4" />
              </video>
            </div>
          }
        </div>
        <div class="order-info">
          <div class="event-name">
            {{ event.name }}
          </div>
          @if (isGeneric) {
            <div class="generic-date-location">
              @if(event.startDate){
                <div class="date-wrapper">
                  <app-date-card
                    [date]="event.startDate"
                    [monthOnly]="true"
                    [brandColors]="true"
                    class="event-date icon"
                  ></app-date-card>
                  <div class="item">
                    <div class="title">
                      {{ event.getEventInfoDateLine1(currentLang) }}
                    </div>
                    <div class="value">
                      @if (event.endDate && event.endsOnSameDate()) {
                        {{ "APP.DATE_TO" | translate }}
                      }
                      {{ event.getEventInfoDateLine2(currentLang) }}
                    </div>
                  </div>
                </div>
              }

              <div class="location-wrapper">
                <div class="icon">
                  <i class="fal fa-map-marker-alt"></i>
                </div>
                <div class="item">
                  <div class="title">
                    {{ event.getAddressDisplayNamePrimary() }}
                  </div>
                  <div class="value">
                    {{ event.getAddressDisplayNameSecondary() }}
                  </div>
                </div>
              </div>
            </div>
          } @else {
            <div class="date-location">
              <div class="location item">
                <div class="title">{{ "APP.LOCATION" | translate }}</div>
                <div class="value">{{ event.eventAddress?.locality }}</div>
              </div>
            </div>
          }

          <app-order-overview
            [isGeneric]="isGeneric"
            [showTitle]="false"
            [showImage]="false"
            [showOrder]="false"
            [ticketOrder]="ticketOrder"
            [mainImage]="mainImage"
            [disableMobileView]="true"
          />
        </div>
      </div>
    </div>
    <div class="download-info">
      @if (ticketOrder?.isProcessing()) {
        {{ "APP.EVENT_PAYMENT.PROCESSING_INSTRUCTIONS" | translate }}
      } @else {
        {{ "APP.EVENT_PAYMENT.DOWNLOAD_INSTRUCTIONS" | translate }}
      }
    </div>
    @if (isDesktop) {
      @if (isGeneric) {
        <div class="action">
          @if (ticketOrder?.isProcessing()) {
            <button class="btn btn-primary-outline processing-btn">
              <div class="loader"></div>
              {{ "APP.EVENT_PAYMENT.PAYMENT_PROCESSING" | translate }}
            </button>
          } @else {
            <button
              class="btn btn-primary-outline"
              (click)="downloadTicketPdf()"
            >
              {{ "APP.EVENT_PAYMENT.DOWNLOAD_TICKETS" | translate }}
            </button>
          }
        </div>
      }
      <div
        class="add-to-wallet-actions"
        [class.disabled]="ticketOrder?.isProcessing()"
      >
        <div class="google-wallet" (click)="addToWallet()">
          <img src="assets/add_to_google_wallet.svg" />
        </div>
        <div class="apple-wallet" (click)="addToWallet()">
          <img src="assets/add-to-apple-wallet.svg" />
        </div>
      </div>

      @if (!isGeneric) {
        <div class="action">
          @if (ticketOrder?.isProcessing()) {
            <button class="btn btn-primary-outline processing-btn">
              <div class="loader"></div>
              {{ "APP.EVENT_PAYMENT.PAYMENT_PROCESSING" | translate }}
            </button>
          } @else {
            <button
              class="btn btn-primary-outline"
              (click)="downloadTicketPdf()"
            >
              {{ "APP.EVENT_PAYMENT.DOWNLOAD_TICKET" | translate }}
            </button>
          }
        </div>
      }
    }
  </div>
  @if (!isDesktop) {
    <app-footer
      [showOptions]="false"
      [includePoweredBy]="true"
      class="footer"
    />
  }
}
