@if (event) {
  <div
    class="event-after-dialog"
    [class.weddinpage-after-dialog]="isWeddingpageEvent"
    [class.invitation-after-dialog]="isInvitationEvent"
    [class.sportpage-after-dialog]="isSportpageEvent"
    [class.ua-after-dialog]="isSportpageEvent && isUaEvent"
  >
    <div
      (click)="close()"
      class="close-button"
      [ngStyle]="{
        color: findSectionByType(EventRSVPConfirmationPageSectionType.MAIN)
          ?.bodyColor
      }"
    >
      <i class="fal fa-times-circle"></i>
    </div>

    <div
      class="after-dialog-header"
      [ngStyle]="{
        'background-color': findSectionByType(
          EventRSVPConfirmationPageSectionType.MAIN
        )?.backgroundColor,
        'background-image':
          'url(' +
          getBackgroundUrl(EventRSVPConfirmationPageSectionType.MAIN) +
          ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        color: findSectionByType(EventRSVPConfirmationPageSectionType.MAIN)
          ?.bodyColor
      }"
    >
      @if (isSportpageEvent) {
        <div class="bg-overlay"></div>
        @if (isMizunoEvent) {
          <h1 class="title">
            {{
              "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.LET_RUN_TOGETHER" | translate
            }}
          </h1>
          <div class="title-desc">
            @if (rsvpType === RSVPOptionType.YES) {
              <p>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.MESSAGE_PREV_SUCCESS"
                    | translate
                }}
                <br class="softbreak" />
                <span class="signal-color">
                  {{
                    "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.MIZUNO_DARK_TOUR"
                      | translate
                  }}</span
                ><br class="softbreak" />
                {{ "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.IN" | translate }}
                <span class="primary-color">{{ event.name }}</span>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.MESSAGE_POST_SUCCESS"
                    | translate
                }}
              </p>
              <br />
              <p>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.THIS_WILL_BE_GREAT"
                    | translate
                }}<br class="softbreak" />
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.SHARE_IT_WITH_YOUR_FRIENDS"
                    | translate
                }}
                🚀
              </p>
            } @else {
              <p>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.MESSAGE_PREV" | translate
                }}
                <br class="softbreak" />
                <span class="signal-color">
                  {{
                    "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.MIZUNO_DARK_TOUR"
                      | translate
                  }}
                </span>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.MESSAGE_POST" | translate
                }}
              </p>
              <br />
              <p>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.DESCRIPTION" | translate
                }}
              </p>
            }
          </div>
        } @else if (isUaEvent) {
          @if (onWaitlist) {
            <h1 class="title">
              Oh nein! Alle Plätze sind schon belegt… aber es gibt noch
              Hoffnung!
            </h1>
          } @else {
            <h1 class="title">LET'S INCREASE RUNNING!</h1>
          }
          <div class="title-desc">
            @if (onWaitlist) {
              <p>
                Vielen Dank für dein Interesse am
                <span class="primary-color">UA Infinite Testival</span>.
                Aufgrund der hohen Nachfrage sind derzeit alle Plätze
                ausgebucht. Aber keine Sorge! Wir haben dich zu unserer
                Warteliste hinzugefügt. Sobald ein Platz frei wird, wirst du
                automatisch benachrichtigt und als Gast gelistet.
              </p>
              <br />
              <p>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.THIS_WILL_BE_GREAT"
                    | translate
                }}<br class="softbreak" />
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.SHARE_IT_WITH_YOUR_FRIENDS"
                    | translate
                }}
                🚀
              </p>
            } @else if (rsvpType === RSVPOptionType.YES) {
              <p>
                Du hast dich erfolgreich zum
                <br class="softbreak" />
                <span class="primary-color"
                  >Under Armour Infinite Elite Testival</span
                >
                <br class="softbreak" />
                in <span class="primary-color">Stuttgart</span> angemeldet.
              </p>
              <br />
              <p>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.THIS_WILL_BE_GREAT"
                    | translate
                }}<br class="softbreak" />
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.SHARE_IT_WITH_YOUR_FRIENDS"
                    | translate
                }}
                🚀
              </p>
            } @else {
              <p>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.MESSAGE_PREV" | translate
                }}
                <br class="softbreak" />
                <span class="primary-color">
                  Under Armour Infinite Elite Testival
                </span>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.MESSAGE_POST" | translate
                }}
              </p>
              <br />
              <p>
                {{
                  "APP.EVENT_AFTER_ATTEND.SPORT_PAGE.DESCRIPTION" | translate
                }}
              </p>
            }
          </div>
        }
      } @else {
        @if (findSectionByType(EventRSVPConfirmationPageSectionType.MAIN)) {
          <div
            class="inner-title big-font"
            [ngStyle]="{
              color: findSectionByType(
                EventRSVPConfirmationPageSectionType.MAIN
              )?.bodyColor
            }"
            [innerHTML]="
              transformToLargerFont(
                getTitle(EventRSVPConfirmationPageSectionType.MAIN)
              ) | safeHtml
            "
          ></div>
          <div
            class="title-desc"
            [ngStyle]="{
              color: findSectionByType(
                EventRSVPConfirmationPageSectionType.MAIN
              )?.bodyColor
            }"
            [innerHTML]="
              getDescription(EventRSVPConfirmationPageSectionType.MAIN)
                | safeHtml
            "
          ></div>
        }
      }

      @if (findSectionByType(EventRSVPConfirmationPageSectionType.MAIN)) {
        <app-share-buttons
          [event]="event"
          [section]="
            findSectionByType(EventRSVPConfirmationPageSectionType.MAIN)
          "
          customToastSuccessClass="toast-success-white-event-primary"
        ></app-share-buttons>
      }

      @if (
        env.platformShort === "partypage" &&
        !isWeddingpageEvent &&
        !isInvitationEvent &&
        !isSportpageEvent &&
        !event.community
      ) {
        <div
          class="title-desc partypage-insta-title"
          [ngStyle]="{
            color: findSectionByType(EventRSVPConfirmationPageSectionType.MAIN)
              ?.bodyColor
          }"
        >
          {{ "APP.EVENT_AFTER_ATTEND.PARTYPAGE_INSTA_TITLE" | translate }}
        </div>
        <img
          class="partypage-insta"
          src="/assets/social/partypage-insta.png"
          (click)="goToPartypageInsta()"
        />
      }
    </div>

    @if (isSportpageEvent) {
      @if (isMizunoEvent) {
        <div class="social-section">
          <div class="title-desc">
            Du kannst es nicht abwarten?
            <br class="softbreak" />
            Lerne Mizuno bereits vorab kennen!
          </div>

          <div class="link-containers">
            <a
              href="http://www.instagram.com/mizunorunningeurope"
              target="_blank"
              class="link-container"
            >
              <div class="link-icon"><i class="fab fa-instagram"></i></div>
              <div class="link-title">mizunorunningeurope</div>
            </a>

            <a
              href="https://emea.mizuno.com/eu"
              target="_blank"
              class="link-container"
            >
              <div class="link-icon"><i class="fal fa-globe"></i></div>
              <div class="link-title">Mizuno.com</div>
            </a>
          </div>
        </div>
      } @else if (isUaEvent) {
        <div class="social-section">
          <div class="title-desc">
            Du kannst es nicht abwarten?
            <br class="softbreak" />
            Lerne Under Armour bereits vorab kennen!
          </div>

          <div class="link-containers">
            <a
              href="https://www.instagram.com/uarunning/?hl=de"
              target="_blank"
              class="link-container"
            >
              <div class="link-icon"><i class="fab fa-instagram"></i></div>
              <div class="link-title">uarunning</div>
            </a>

            <a
              href="https://underarmour.de/de-de"
              target="_blank"
              class="link-container"
            >
              <div class="link-icon"><i class="fal fa-globe"></i></div>
              <div class="link-title">underarmour.de</div>
            </a>
          </div>
        </div>
      }

      <div class="create-event-section">
        <h2 class="body-header">
          Du willst ein eigenes Event auf die Beine Stellen?
        </h2>
        <div class="subtitle">
          {{ "APP.EVENT_AFTER_ATTEND.CREATE_EVENT_DESC" | translate }}
        </div>

        <div class="design-templates-list">
          @for (designTemplate of designTemplates; track designTemplate.id) {
            <app-design-template-card
              [template]="designTemplate"
              [size]="180"
              [noCallback]="true"
              [loggedUser]="loggedUser"
              (templateChosen)="onTemplateChosen($event)"
            ></app-design-template-card>
          }
        </div>

        <button
          type="button"
          class="btn btn-primary action-btn"
          (click)="createEvent()"
        >
          {{ "APP.EVENT_AFTER_ATTEND.CREATE_EVENT_ACTION" | translate }}
        </button>
      </div>
    }

    @if (findSectionByType(EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA)) {
      <div
        class="section social-media-section"
        [ngStyle]="{
          'background-color': findSectionByType(
            EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA
          )?.backgroundColor,
          'background-image':
            'url(' +
            getBackgroundUrl(
              EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA
            ) +
            ')',
          'background-repeat': 'no-repeat',
          'background-size': 'cover'
        }"
      >
        <div class="text">
          <div
            class="inner-title"
            [ngStyle]="{
              color: findSectionByType(
                EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA
              )?.bodyColor
            }"
            [innerHTML]="
              getTitle(EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA)
                | safeHtml
            "
          ></div>
          <div
            class="title-desc"
            [ngStyle]="{
              color: findSectionByType(
                EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA
              )?.bodyColor
            }"
            [innerHTML]="
              getDescription(EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA)
                | safeHtml
            "
          ></div>
        </div>

        <app-edit-social-media-buttons
          [section]="
            findSectionByType(EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA)
          "
          [isEditMode]="false"
        />
      </div>
    }

    @if (!isInvitationEvent && !isSportpageEvent) {
      @if (
        event &&
        event.getEventCampaignInsuranceCheckLink() &&
        event.getEventCampaignInsuranceCheckLink() !== ""
      ) {
        <div class="after-dialog-body insurance-check-body">
          <h2 class="body-header">
            {{
              "APP.EVENT_AFTER_ATTEND.INSURANCE_CHECK_LINK_TITLE" | translate
            }}
          </h2>
          <div
            class="subtitle"
            [innerText]="
              'APP.EVENT_AFTER_ATTEND.INSURANCE_CHECK_LINK_DESC' | translate
            "
          ></div>

          <app-insurance-check-share-options
            [event]="event"
            [isAfterAttend]="true"
            class="share-options"
          />
        </div>
      }

      @if (canInviteFellows) {
        <app-invite-friends [event]="event" />
      }

      @if (wishlistProducts.length > 0 || affiliateProducts.length > 0) {
        <div class="after-dialog-body">
          @if (wishlistProducts.length > 0) {
            <h2 class="body-header">
              {{ "APP.EVENT_AFTER_ATTEND.WISHLIST_TITLE" | translate }}
            </h2>
            <div class="subtitle">
              {{ "APP.EVENT_AFTER_ATTEND.WISHLIST_DESC" | translate }}
            </div>
            <div class="affiliate-list">
              @for (
                affiliateProduct of wishlistProducts;
                track affiliateProduct.id
              ) {
                <app-affiliate-product-card
                  [affiliateProduct]="affiliateProduct"
                >
                </app-affiliate-product-card>
              }
            </div>

            <button
              type="button"
              class="btn btn-primary action-btn"
              (click)="moreWishlist()"
            >
              {{ "APP.EVENT_AFTER_ATTEND.WISHLIST_MORE" | translate }}
            </button>
          }

          @if (affiliateProducts.length > 0) {
            <h2 class="body-header">
              {{
                "APP.EVENT_AFTER_ATTEND.AFFILIATE_PRODUCTS_TITLE" | translate
              }}
            </h2>
            <div class="subtitle">
              {{ "APP.EVENT_AFTER_ATTEND.AFFILIATE_PRODUCTS_DESC" | translate }}
            </div>

            <div class="affiliate-list">
              @for (
                affiliateProduct of affiliateProducts;
                track affiliateProduct.id
              ) {
                <app-affiliate-product-card
                  [affiliateProduct]="affiliateProduct"
                >
                </app-affiliate-product-card>
              }
            </div>

            <button
              type="button"
              class="btn btn-primary action-btn"
              (click)="moreAffiliateProducts()"
            >
              {{ "APP.EVENT_AFTER_ATTEND.AFFILIATE_PRODUCTS_MORE" | translate }}
            </button>
          }
        </div>
      }

      @if (
        findSectionByType(EventRSVPConfirmationPageSectionType.CREATE_EVENT)
      ) {
        <div
          class="create-event-section section"
          [class.grey-section]="
            wishlistProducts.length > 0 || affiliateProducts.length > 0
          "
          [ngStyle]="{
            'background-color': findSectionByType(
              EventRSVPConfirmationPageSectionType.CREATE_EVENT
            )?.backgroundColor,
            'background-image':
              'url(' +
              getBackgroundUrl(
                EventRSVPConfirmationPageSectionType.CREATE_EVENT
              ) +
              ')',
            'background-repeat': 'no-repeat',
            'background-size': 'cover'
          }"
        >
          @if (
            findSectionByType(EventRSVPConfirmationPageSectionType.CREATE_EVENT)
          ) {
            <div class="text">
              <div
                class="inner-title"
                [ngStyle]="{
                  color: findSectionByType(
                    EventRSVPConfirmationPageSectionType.CREATE_EVENT
                  )?.bodyColor
                }"
                [innerHTML]="
                  getTitle(EventRSVPConfirmationPageSectionType.CREATE_EVENT)
                    | safeHtml
                "
              ></div>
              <div
                class="title-desc"
                [ngStyle]="{
                  color: findSectionByType(
                    EventRSVPConfirmationPageSectionType.CREATE_EVENT
                  )?.bodyColor
                }"
                [innerHTML]="
                  getDescription(
                    EventRSVPConfirmationPageSectionType.CREATE_EVENT
                  ) | safeHtml
                "
              ></div>
            </div>
          }

          <div class="design-templates-list">
            @for (designTemplate of designTemplates; track designTemplate.id) {
              <app-design-template-card
                [template]="designTemplate"
                [size]="180"
                [noCallback]="true"
                [eventColors]="true"
                [loggedUser]="loggedUser"
                (templateChosen)="onTemplateChosen($event)"
              ></app-design-template-card>
            }
          </div>

          <button type="button" class="btn action-btn" (click)="createEvent()">
            {{ "APP.EVENT_AFTER_ATTEND.CREATE_EVENT_ACTION" | translate }}
          </button>
        </div>
      }
    }

     @if (findSectionByType(EventRSVPConfirmationPageSectionType.HOST) && isWeddingpageEvent) {
      <div
        class="host-section section"
        [ngStyle]="{
          'background-color': findSectionByType(
            EventRSVPConfirmationPageSectionType.HOST
          )?.backgroundColor,
          'background-image':
            'url(' +
            getBackgroundUrl(EventRSVPConfirmationPageSectionType.HOST) +
            ')',
          'background-repeat': 'no-repeat',
          'background-size': 'cover'
        }"
      >
        <div
          class="user-section"
          [ngStyle]="{
            color: findSectionByType(EventRSVPConfirmationPageSectionType.HOST)
              ?.bodyColor
          }"
        >
          <app-user-avatar
            [user]="loggedUser"
            [size]="86.5"
            [placeholderFontSize]="32"
          ></app-user-avatar>
          <div class="info">
            <span class="user-name">{{ loggedUser?.getDisplayName() }}</span>
            @if (profileInfo) {
              <span class="num-events"
                >{{ profileInfo.numParties }}
                {{ "APP.PROFILE.EVENTS" | translate }}</span
              >
            }
          </div>
        </div>
      </div>
    }

    <!--   <div class="follow-creator-section">
      @if (event.hostUser && event.hostUser.avatarUrl !== "") {
        <img class="creator-avatar" src="{{ event.hostUser.avatarUrl }}" />
      } @else {
        <div class="creator-avatar-placeholder">
          {{ event.hostUser?.getInitials() }}
        </div>
      }
      <div class="creator-name">{{ event.hostUser?.name }}</div>
      <div class="creator-followers">250 {{ "APP.FOLLOWERS" | translate }}</div>

      <button
        type="button"
        class="btn btn-white-primary action-btn"
        (click)="followCreator()"
      >
        {{ "APP.FOLLOW_CREATOR" | translate }}
      </button>
    </div>-->

    @if (isWeddingpageEvent && font) {
      <app-footer
        class="after-footer"
        [showLogo]="true"
        [backgroundColor]="
          font.sectionAccentBackgroundColor !== ''
            ? font.sectionAccentBackgroundColor
            : ''
        "
      ></app-footer>
    } @else if (isInvitationEvent && visual) {
      <app-footer
        class="after-footer"
        [showLogo]="true"
        [backgroundColor]="visual.backgroundColor ? visual.backgroundColor : ''"
      ></app-footer>
    } @else if (isSportpageEvent) {
      <div class="after-footer">
        <div class="company-info">
          Veranstalter ist die B2M Creative GmbH<br />
          Lychener Straße 51, 10437, Berlin
        </div>
        <a
          href="https://www.b2m-creative.com/home/datenschutz"
          target="_blank"
          class="text-link"
          >Datenschutzrichtlinien</a
        >
        <div class="footer-socials">
          <a
            href="https://www.instagram.com/mysportspassion"
            target="_blank"
            class="text-link"
            ><i class="fab fa-instagram"></i
          ></a>
          <a
            href="https://www.linkedin.com/company/b2m-creative/"
            target="_blank"
            class="text-link"
            ><i class="fab fa-linkedin"></i
          ></a>
          <a
            href="https://www.b2m-creative.com/"
            target="_blank"
            class="text-link"
            ><i class="fal fa-globe"></i
          ></a>
        </div>
        <a href="mailto:mizuno@b2m-marketing.de" class="text-link"
          >Contact us</a
        >
      </div>
    } @else {
      <app-footer class="after-footer" [showLogo]="true"></app-footer>
    }
  </div>
}
