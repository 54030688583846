import { CommonModule, isPlatformServer } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Event } from '@models/events/event.model';
import { TranslateModule } from '@ngx-translate/core';
import { EventSectionMapComponent } from '../event-section-map/event-section-map.component';

@Component({
  selector: 'app-event-details',
  standalone: true,
  imports: [CommonModule, TranslateModule, EventSectionMapComponent],
  templateUrl: './event-details.component.html',
  styleUrl: './event-details.component.scss',
})
export class EventDetailsComponent {
  @Input() event?: Event;
  @Input() isDesktop = true;

  isServer = false;

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isServer = isPlatformServer(platformId);
  }
}
