import { NgStyle, UpperCasePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { EventTemplateLayout } from '@models/design-templates/event-template-layout.enum';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { TranslateModule } from '@ngx-translate/core';
import { EventStore } from '@services/stores/event.store';
import { TextSizeDirective } from '../../../../directives/text-size.directive';

@Component({
  selector: 'app-event-section-map',
  standalone: true,
  imports: [
    GoogleMapsModule,
    TranslateModule,
    UpperCasePipe,
    TextSizeDirective,
    NgStyle,
  ],
  templateUrl: './event-section-map.component.html',
  styleUrl: './event-section-map.component.scss',
})
export class EventSectionMapComponent implements OnInit, AfterViewInit {
  @Input() disableActions?: boolean = false;
  @Input() editView?: boolean = false;
  @Input() bodyColor?: string;
  mapZoom = 13;
  mapCenter: google.maps.LatLngLiteral = {
    lat: 52.52,
    lng: 13.4,
  };
  markerPosition = this.mapCenter;
  markerPositionMap = new Map();
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 4,
    draggableCursor: 'default',
    styles: [
      {
        featureType: 'all',
        elementType: 'all',
        stylers: [{ saturation: -100 }],
      },
    ],
  };
  mapTypeId = 'tehgrayz';
  mapTypeOptions = {
    name: 'Grayscale',
  };
  noActionsMapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  styles: google.maps.MapTypeStyle[] = [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [{ saturation: -100 }],
    },
  ];
  ngAfterViewInit(): void {
    if (
      this.map &&
      (this.event?.type === EventType.SPORTPAGE ||
        this.event?.type === EventType.LANDINGPAGE)
    ) {
      const googleMap = this.map.googleMap;

      const styledMapType = new google.maps.StyledMapType(
        this.styles,
        this.mapTypeOptions,
      );
      googleMap?.mapTypes.set(this.mapTypeId, styledMapType);
      googleMap?.setMapTypeId(this.mapTypeId);
    }
  }

  markerOptions: google.maps.marker.AdvancedMarkerElementOptions = {
    gmpDraggable: false,
  };

  loading = false;
  skipFindCoordinates = false;
  isEventEditPicker = false;

  @ViewChild(GoogleMap, { static: false }) map?: GoogleMap;

  event?: Event;
  events?: Event[];
  isDarkTheme: boolean = false;

  constructor(private eventStore: EventStore) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;
        if (this.event?.eventAddress) {
          this.markerPosition = {
            lat: this.event.eventAddress.lat,
            lng: this.event.eventAddress.lng,
          };
          this.mapCenter = this.markerPosition;

          if (
            this.event.eventAddress.isFilled() ||
            this.event.eventAddress.locality === 'Berlin'
          ) {
            this.mapZoom = 17;
          } else {
            this.mapZoom = 12;
          }
        }
      }
    });
  }

  ngOnInit(): void {
    if (this.event?.type === EventType.LANDINGPAGE) {
      this.events = this.event.getLandingPageEvents();
      this.isDarkTheme = true;
      if (this.events) {
        this.events.forEach((x, i) => {
          if (x.eventAddress) {
            const markerPosition = {
              lat: x.eventAddress?.lat,
              lng: x.eventAddress?.lng,
            };
            this.markerPositionMap.set(x.id, markerPosition);

            if (i === 0) {
              this.mapCenter = markerPosition;
            }
          }
        });

        this.mapZoom = 6;
      }
    }

    if (this.event?.type === EventType.ADVANCED_ONE_COLUMN) {
      this.mapOptions.scrollwheel = false;
    }
  }

  getPosition(eventId: number) {
    return this.markerPositionMap.get(eventId);
  }

  openGoogleMaps() {
    if (
      !this.event ||
      (!this.event.eventAddress && !this.events) ||
      this.editView
    ) {
      return;
    }

    if (this.events && this.events.length > 0) {
      window.open(this.events[0].getGoogleMapsLink(), '_blank');
    } else {
      window.open(this.event.getGoogleMapsLink(), '_blank');
    }
  }

  getMarkerOptions(): google.maps.marker.AdvancedMarkerElementOptions {
    if (
      this.isUaSection ||
      this.event?.type === EventType.ADVANCED_ONE_COLUMN
    ) {
      const markerImg = document.createElement('img');
      markerImg.src =
        'https://static.eventpage.ai/event-templates/event-templates-1594/map-pin.png';
      markerImg.style.width = '40px';
      markerImg.style.height = '40px';
      return {
        gmpDraggable: false,
        content: markerImg,
      };
    } else {
      const markerImg = document.createElement('img');
      markerImg.src = '/assets/pink-marker.png';
      markerImg.style.width = '30px';
      markerImg.style.height = '40px';
      return {
        gmpDraggable: false,
        content: markerImg,
      };
    }
  }

  get isUaSection(): boolean {
    return !!this.event?.uri.includes('underarmour-infinite-elite-testival');
  }

  protected readonly Event = Event;
  protected readonly EventType = EventType;
  protected readonly EventTemplateLayout = EventTemplateLayout;
}
