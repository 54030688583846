import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment as env } from '@environments/environment';
import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { EventTemplateCardData } from '@models/design-templates/event-template-card-data.model';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventAffiliateProductType } from '@models/events/event-affiliate-product-type.enum';
import { EventRSVPConfirmationPageSectionType } from '@models/events/event-rsvp-confirmation-page-section-type.enum';
import { EventRsvpConfirmationPage } from '@models/events/event-rsvp-confirmation-page.model';
import { EventRSVPConfirmationPageType } from '@models/events/event-rsvp-confirmation-page.type.enum';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { UserProfileInfo } from '@models/users/dto/user-profile-info.response';
import { User } from '@models/users/user.model';
import { AffiliateProductCardComponent } from '@modules/admin/affiliates-shared/components/affiliate-product-card/affiliate-product-card.component';
import { EditSocialMediaButtonsComponent } from '@modules/events/components/edit-social-media-buttons/edit-social-media-buttons.component';
import { InsuranceCheckShareOptionsComponent } from '@modules/events/components/insurance-check-share-options/insurance-check-share-options.component';
import { InviteFriendsComponent } from '@modules/events/components/invite-friends/invite-friends.component';
import { DesignTemplateCardComponent } from '@modules/shared/components/design-template-card/design-template-card.component';
import { ShareButtonsComponent } from '@modules/shared/components/share-buttons/share-buttons.component';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { UserService } from '@services/auth/user.service';
import { EventTemplateService } from '@services/events/event-template.service';
import { EventService } from '@services/events/event.service';
import { FooterComponent } from 'app/components/footer/footer.component';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { AppRoutes } from 'app/routes';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-event-after-attend-overlay-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ShareButtonsComponent,
    AffiliateProductCardComponent,
    DesignTemplateCardComponent,
    FooterComponent,
    InviteFriendsComponent,
    EditSocialMediaButtonsComponent,
    InsuranceCheckShareOptionsComponent,
    SafeHtmlPipe,
    UserAvatarComponent,
  ],
  providers: [EventTemplateService, EventService, UserService],
  templateUrl: './event-after-attend-overlay-dialog.component.html',
  styleUrl: './event-after-attend-overlay-dialog.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventAfterAttendOverlayDialogComponent implements OnInit {
  wishlistProducts: AffiliateProduct[] = [];
  affiliateProducts: AffiliateProduct[] = [];
  shoppingListAffiliateProduct?: AffiliateProduct;
  designTemplates: EventTemplateCardData[] = [];

  event?: Event;
  rsvpType: RSVPOptionType = RSVPOptionType.YES;
  onWaitlist: boolean = false;
  onPendingList: boolean = false;
  canInviteFellows: boolean = false;

  eventRsvpConfirmationPage?: EventRsvpConfirmationPage;
  loggedUser?: User;
  profileInfo?: UserProfileInfo;

  swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    speed: 2000,

    spaceBetween: 1,
    injectStyles: [
      `
        :host .swiper { overflow: visible;}
      `,
    ],
  };
  constructor(
    public dialogRef: MatDialogRef<EventAfterAttendOverlayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventTemplateService: EventTemplateService,
    private router: Router,
    private eventService: EventService,
    private translate: TranslateService,
    private authService: AuthService,
    private userService: UserService,
  ) {
    if (data?.event) {
      this.rsvpType = data.rsvpType;
      this.event = data.event;
      this.onWaitlist = data.onWaitlist;
      this.onPendingList = data.onPendingList;
      this.canInviteFellows = this.event?.canAttendeeInviteMutuals
        ? this.event?.canAttendeeInviteMutuals
        : false;
      if (this.event?.eventTemplate) {
        this.wishlistProducts =
          this.event.eventTemplate.getAfterAffiliateProducts(
            EventAffiliateProductType.AFTER_ATTEND_GIFT,
          );

        this.affiliateProducts =
          this.event.eventTemplate.getAfterAffiliateProducts(
            EventAffiliateProductType.AFTER_ATTEND,
          );

        const shoppingListAffiliateProductArr =
          this.event.eventTemplate.getAfterAffiliateProducts(
            EventAffiliateProductType.AFTER_ATTEND_SHOPPING_LIST,
          );

        this.shoppingListAffiliateProduct =
          shoppingListAffiliateProductArr?.length > 0
            ? shoppingListAffiliateProductArr[0]
            : undefined;
      }

      this.eventTemplateService.fetch().subscribe({
        next: (templates) => {
          this.designTemplates = templates;
        },
      });
      this.authService.userSubject
        .pipe(takeUntilDestroyed())
        .subscribe((user) => {
          this.loggedUser = user;
          if (user.id) {
            this.userService
              .getUserProfileInfo(user.id)
              .subscribe((profileInfo) => {
                this.profileInfo = profileInfo;
              });
          }
        });
    }
  }

  ngOnInit(): void {
    let pageType: EventRSVPConfirmationPageType;

    switch (this.afterScreenRsvpType) {
      case RSVPOptionType.NO:
        pageType = EventRSVPConfirmationPageType.NO;
        break;
      case RSVPOptionType.WAITLIST:
        pageType = EventRSVPConfirmationPageType.WAITLIST;
        break;
      case RSVPOptionType.MAYBE:
        pageType = EventRSVPConfirmationPageType.MAYBE;
        break;
      case RSVPOptionType.PENDING_APPROVAL:
        pageType = EventRSVPConfirmationPageType.PENDING;
        break;
      default:
        pageType = EventRSVPConfirmationPageType.YES;
        break;
    }

    if (this.event) {
      this.eventService
        .getRSVPConfirmationPageByType(this.event.id, pageType)
        .subscribe({
          next: (res) => {
            this.eventRsvpConfirmationPage = res;
          },
        });
    }
  }

  getTitle(type: EventRSVPConfirmationPageSectionType) {
    const section = this.findSectionByType(type);
    if (!section) return;
    let title;
    if (
      section.title === null ||
      section.title === undefined ||
      section.title.trim() === ''
    ) {
      if (type === EventRSVPConfirmationPageSectionType.MAIN) {
        switch (this.eventRsvpConfirmationPage?.type) {
          case EventRSVPConfirmationPageType.NO:
            if (this.event?.type === EventType.INVITATION) {
              title = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.INVITATION.NOT_ATTENDING_HEADER',
              );
            } else {
              title = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.NOT_ATTENDING_HEADER',
              );
            }
            break;
          case EventRSVPConfirmationPageType.WAITLIST:
            if (this.event?.type === EventType.INVITATION) {
              title =
                this.translate.instant('APP.CONGRATS') +
                '\n' +
                this.translate.instant(
                  'APP.EVENT_AFTER_ATTEND.INVITATION.JOIN_WAITLIST_SUCCESS',
                );
            } else {
              title =
                this.translate.instant('APP.CONGRATS') +
                '\n' +
                this.translate.instant(
                  'APP.EVENT_AFTER_ATTEND.JOIN_WAITLIST_SUCCESS',
                );
            }
            break;
          case EventRSVPConfirmationPageType.PENDING:
            if (this.event?.type === EventType.INVITATION) {
              title = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.INVITATION.GET_ON_THE_LIST_SUCCESS',
              );
            } else {
              title = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.GET_ON_THE_LIST_SUCCESS',
              );
            }
            break;
          default:
            if (this.event?.type === EventType.INVITATION) {
              title = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.INVITATION.ATTENDING_HEADER',
              );
            } else {
              title = this.translate.instant('APP.CONGRATS');
            }
            break;
        }
      } else if (type === EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA) {
        title = this.translate.instant(
          'APP.EVENT_AFTER_ATTEND.SECTIONS.SOCIAL_MEDIA_TITLE',
        );
      } else if (type === EventRSVPConfirmationPageSectionType.CREATE_EVENT) {
        title = this.translate.instant(
          'APP.EVENT_AFTER_ATTEND.CREATE_EVENT_TITLE',
        );
      }
    } else {
      title = section.title;
    }
    return title;
  }

  transformToLargerFont(htmlString: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const paragraphs = doc.querySelectorAll('p');
    paragraphs.forEach((p) => {
      p.classList.add('big-font');
    });
    return doc.body.innerHTML;
  }

  getDescription(type: EventRSVPConfirmationPageSectionType) {
    const section = this.findSectionByType(type);
    if (!section) return;
    let description;
    if (section.description === null || section.description === undefined) {
      if (type === EventRSVPConfirmationPageSectionType.MAIN) {
        switch (this.eventRsvpConfirmationPage?.type) {
          case EventRSVPConfirmationPageType.NO:
            if (this.event?.type === EventType.INVITATION) {
              description = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.INVITATION.NOT_ATTENDING_TITLE',
                { eventName: this.event.name },
              );
            } else {
              description = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.NOT_ATTENDING_TITLE',
              );
            }
            break;
          case EventRSVPConfirmationPageType.WAITLIST:
            if (this.event?.type === EventType.INVITATION) {
              description = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.INVITATION.JOIN_WAITLIST_SUCCESS_DESCRIPTION',
              );
            } else {
              description = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.JOIN_WAITLIST_SUCCESS_DESCRIPTION',
              );
            }
            break;
          case EventRSVPConfirmationPageType.PENDING:
            if (this.event?.type === EventType.INVITATION) {
              description = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.INVITATION.GET_ON_THE_LIST_SUCCESS_DESCRIPTION',
                { eventName: this.event.name },
              );
            } else {
              description = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.GET_ON_THE_LIST_SUCCESS_DESCRIPTION',
              );
            }
            break;
          default:
            if (this.event?.type === EventType.INVITATION) {
              description = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.INVITATION.ATTENDING_TITLE',
                { eventName: this.event.name },
              );
            } else {
              description = this.translate.instant(
                'APP.EVENT_AFTER_ATTEND.TITLE',
              );
            }
            break;
        }
      } else if (type === EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA) {
        description = this.translate.instant(
          'APP.EVENT_AFTER_ATTEND.SECTIONS.SOCIAL_MEDIA_DESCRIPTION',
        );
      } else if (type === EventRSVPConfirmationPageSectionType.CREATE_EVENT) {
        description = this.translate.instant(
          'APP.EVENT_AFTER_ATTEND.CREATE_EVENT_DESC',
        );
      }
    } else {
      description = section.description;
    }
    return description;
  }

  findSectionByType(type: EventRSVPConfirmationPageSectionType) {
    if (type === EventRSVPConfirmationPageSectionType.SOCIAL_MEDIA) {
      const socialMediaSection = this.eventRsvpConfirmationPage?.sections.find(
        (x) => x.type === type && x.visible,
      );
      if (!socialMediaSection) return;
      else {
        if (
          socialMediaSection.socialMediaLinks &&
          socialMediaSection.socialMediaLinks?.length > 0
        ) {
          return socialMediaSection;
        } else return;
      }
    } else {
      return this.eventRsvpConfirmationPage?.sections.find(
        (x) => x.type === type && x.visible,
      );
    }
  }

  getBackgroundUrl(type: EventRSVPConfirmationPageSectionType) {
    const currentSection = this.findSectionByType(type);

    if (currentSection && currentSection.backgroundUrl) {
      return currentSection.backgroundUrl;
    } else {
      return this.afterScreenBackgroundImage;
    }
  }

  close() {
    this.dialogRef.close();
  }

  moreWishlist() {}

  moreAffiliateProducts() {
    if (this.shoppingListAffiliateProduct) {
      window.open(this.shoppingListAffiliateProduct.link, '_blank');
    }
  }

  createEvent() {
    this.router.navigate(['/', AppRoutes.Events.createEvent]);
    this.close();
  }

  onTemplateChosen(templateId: number) {
    this.router.navigate(['/', AppRoutes.Events.createEvent], {
      queryParams: { templateId: templateId },
    });
    this.close();
  }

  followCreator() {}

  goToPartypageInsta() {
    window.open('https://www.instagram.com/partypage.ai/', '_blank');
  }

  get isWeddingpageEvent(): boolean {
    return this.event?.type === EventType.WEDDINGPAGE;
  }

  get isInvitationEvent(): boolean {
    return this.event?.type === EventType.INVITATION;
  }

  get isSportpageEvent(): boolean {
    return this.event?.type === EventType.SPORTPAGE;
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  get afterScreenRsvpType(): RSVPOptionType {
    if (this.rsvpType === RSVPOptionType.NO) {
      return this.rsvpType;
    }

    if (this.onPendingList) {
      return RSVPOptionType.PENDING_APPROVAL;
    } else if (this.onWaitlist) {
      return RSVPOptionType.WAITLIST;
    }

    return this.rsvpType;
  }

  get rsvpOptionAfterScreenTitle(): string | undefined {
    return this.event?.getRsvpConfirmationTextByType(this.afterScreenRsvpType)
      ?.title;
  }

  get afterScreenDescription(): string | undefined {
    return this.event?.getRsvpConfirmationTextByType(this.afterScreenRsvpType)
      ?.description;
  }

  get afterScreenBackgroundImage(): string {
    if (!this.isSportpageEvent) {
      return '';
    }

    if (this.isMizunoEvent) {
      return encodeURI(
        'https://static.eventpage.ai/event-templates/event-templates-1485/after-rsvp-yes-bg.jpg',
      );
    } else if (this.isUaEvent) {
      return encodeURI(
        'https://static.eventpage.ai/event-templates/event-templates-1594/female-running-under-armour.png',
      );
    }

    return '';
  }

  get isMizunoEvent(): boolean {
    return !!this.event?.uri.includes('mizuno');
  }

  get isUaEvent(): boolean {
    return !!this.event?.uri.includes('underarmour-infinite-elite-testival');
  }

  protected readonly RSVPOptionType = RSVPOptionType;
  protected readonly EventRSVPConfirmationPageSectionType =
    EventRSVPConfirmationPageSectionType;
  protected readonly env = env;
}
