@if (event && visual && font) {
  <div class="page-container">
    @if (visual.patternUrl) {
      <img class="pattern" [src]="visual.patternUrl" />
    }
    <div class="event-container" [class.host-view]="isHostView">
      <div class="party-action-container">
        @if (!eventActionsSticky) {
          @if (event.mainPictureUrl || visual.keyVisual1) {
            <div
              class="main-picture"
              [class.smaller-view]="orders && hasRequestedRefund()"
            >
              @if (event.isMainPictureVideo()) {
                <video
                  class="key-visual-1-video"
                  [muted]="'muted'"
                  autoplay
                  playsinline
                  loop
                >
                  <source [src]="event.mainPictureUrl" type="video/mp4" />
                </video>
              } @else {
                <img
                  class="key-visual-1"
                  [src]="event.mainPictureUrl || visual.keyVisual1?.url"
                />
              }
              @if (
                event.hasCampaignLogo() &&
                event.eventCampaign?.campaign?.logoUrl
              ) {
                <div>
                  <img
                    class="campaign-logo"
                    [src]="event.eventCampaign?.campaign?.logoUrl"
                  />
                </div>
              }
              @if (isCampaign && campaignData) {
                <app-campaign-ranking
                  [campaignData]="campaignData"
                  [event]="event"
                  [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                />
              }
            </div>
          }
          @if (!isVotingView && !isCohostInvitation) {
            @if (event.hasApp(EventWidget.SELL_TICKETS)) {
              @if (isHostView || event.hasPublishedSellTickets()) {
                <div class="tickets-section">
                  @if (isHostView && !event.hasPublishedSellTickets()) {
                    <div
                      class="publish-sell-tickets-host-view"
                      (click)="onPublishSellTickets()"
                    >
                      <span>{{
                        "APP.TICKETING_SET_UP.PUBLISH_SELL_TICKETS" | translate
                      }}</span>
                      <i class="far fa-chevron-right"></i>
                    </div>
                  }
                  @if (orders) {
                    @if (hasRequestedRefund()) {
                      <div class="buy-tickets-wrapper one-row">
                        <div class="header">
                          <i class="far fa-redo"></i>
                          <div>
                            {{
                              "APP.REQUEST_REFUND.YOU_REQUESTED_REFUND"
                                | translate
                            }}
                          </div>
                        </div>
                        <button
                          class="btn btn-primary"
                          (click)="openRequestedRefundOverviewDialog()"
                        >
                          <i class="fas fa-chevron-right"></i>
                        </button>
                      </div>
                    }
                    <div class="event-actions">
                      <div
                        class="buy-tickets-wrapper"
                        [class.bought-ticket-wrapper]="orders"
                      >
                        <div class="header">
                          <i class="far fa-ticket"></i>
                          <div>
                            {{
                              "APP.REQUEST_REFUND.YOU_BOUGHT_TICKET" | translate
                            }}
                          </div>
                        </div>
                        <button
                          class="btn btn-primary"
                          (click)="openGetBoughtTicketsDialog()"
                        >
                          {{ "APP.REQUEST_REFUND.OPEN_TICKET" | translate }}
                        </button>
                        <div
                          class="header action"
                          (click)="openGetTicketsDialog()"
                        >
                          {{ "APP.EVENT.BUY_MORE_TICKETS" | translate }}
                          <i class="far fa-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                    <app-share-buttons
                      [event]="event"
                      [isEventPage]="true"
                    ></app-share-buttons>
                  } @else {
                    <div class="event-actions">
                      <div class="buy-tickets-wrapper">
                        <div class="header">
                          <i class="far fa-shopping-cart"></i>
                          <div>
                            {{
                              event.actionSectionTitle ||
                                "APP.EVENT.BUY_TICKETS_FOR_THE_EVENT"
                                | translate
                            }}
                          </div>
                        </div>
                        <button
                          class="btn btn-primary"
                          (click)="openGetTicketsDialog()"
                        >
                          {{ "APP.EVENT.GET_TICKETS_FROM" | translate }}
                          {{ event.getLowestTicketPriceDisplay() }}
                        </button>
                      </div>
                      <app-share-buttons
                        [event]="event"
                        [isEventPage]="true"
                      ></app-share-buttons>
                    </div>
                  }
                </div>
              }
            } @else {
              @if (isAttendeeView) {
                @if (event.hasOneButtonSignUp() || event.hasTwoButtonSignUp()) {
                  <div class="event-actions attendee-view">
                    <div class="share-button small" (click)="addToCalendar()">
                      <i class="far fa-calendar-day"></i>
                    </div>
                    <app-event-rsvp
                      [loggedUserId]="loggedUser?.id"
                      [isLoggedUserHost]="
                        loggedUser &&
                        loggedUser.id &&
                        (event.isUserHost(loggedUser.id) ||
                          loggedUser.isAdmin())
                      "
                      [showGuestCount]="event.showGuestCount"
                      [isAttendeeView]="isAttendeeView"
                      [rsvpObservable$]="rsvpObservable$"
                      class="buttons-rsvp"
                    ></app-event-rsvp>
                    <div class="share-button small" (click)="shareEvent()">
                      <i class="far fa-share"></i>
                    </div>
                    <!--     <app-share-buttons
                           [event]="event"
                           [isEventPage]="true"
                         ></app-share-buttons>-->
                  </div>
                } @else {
                  <div class="event-actions attendee-view">
                    <div class="share-button" (click)="addToCalendar()">
                      <i class="far fa-calendar-day"></i>
                    </div>
                    <app-event-rsvp
                      [loggedUserId]="loggedUser?.id"
                      [isLoggedUserHost]="
                        loggedUser &&
                        loggedUser.id &&
                        (event.isUserHost(loggedUser.id) ||
                          loggedUser.isAdmin())
                      "
                      [showGuestCount]="event.showGuestCount"
                      [isAttendeeView]="isAttendeeView"
                      [rsvpObservable$]="rsvpObservable$"
                    ></app-event-rsvp>
                    <div class="share-button" (click)="shareEvent()">
                      <i class="far fa-share"></i>
                    </div>
                  </div>
                }
              } @else if (!event.maxCapacityReached()) {
                @if (event.requireAttendeeApproval) {
                  @if (userOnPendingList) {
                    <button class="btn transparent-button disabled-icon-button">
                      <i class="far fa-clock icon"></i>
                      {{ "APP.WAIT_LIST.PENDING_APPROVAL" | translate }}
                    </button>
                  } @else if (event.canRsvp()) {
                    <div class="rsvp-navigate-wrapper">
                      <div class="header">
                        {{
                          event.actionSectionTitle ||
                            "APP.EVENT.RSVP_TO_THE_EVENT" | translate
                        }}
                      </div>
                      <button
                        class="btn transparent-button"
                        (click)="getOnPendingList()"
                      >
                        {{ "APP.WAIT_LIST.GET_ON_THE_LIST" | translate }}
                      </button>
                    </div>
                  }
                } @else if (
                  event.canRsvp() || isUserHost || loggedUser?.isAdmin()
                ) {
                  <div
                    class="event-actions"
                    [class.one-button-actions]="event.hasOneButtonSignUp()"
                  >
                    @if (
                      event.hasOneButtonSignUp() || event.hasTwoButtonSignUp()
                    ) {
                      <div class="rsvp-navigate-wrapper">
                        <div class="header">
                          {{
                            event.actionSectionTitle ||
                              "APP.EVENT.RSVP_TO_THE_EVENT" | translate
                          }}
                        </div>
                        <app-event-rsvp
                          [loggedUserId]="loggedUser?.id"
                          [isLoggedUserHost]="
                            loggedUser &&
                            loggedUser.id &&
                            (event.isUserHost(loggedUser.id) ||
                              loggedUser.isAdmin())
                          "
                          [showGuestCount]="event.showGuestCount"
                          [rsvpObservable$]="rsvpObservable$"
                        ></app-event-rsvp>
                      </div>
                    } @else {
                      <app-event-rsvp
                        [loggedUserId]="loggedUser?.id"
                        [isLoggedUserHost]="
                          loggedUser &&
                          loggedUser.id &&
                          (event.isUserHost(loggedUser.id) ||
                            loggedUser.isAdmin())
                        "
                        [showGuestCount]="event.showGuestCount"
                        [rsvpObservable$]="rsvpObservable$"
                      ></app-event-rsvp>
                    }
                    <app-share-buttons
                      [event]="event"
                      [isEventPage]="true"
                    ></app-share-buttons>
                  </div>
                }
              } @else if (event.maxCapacityReached() && event.waitlistEnabled) {
                @if (userOnWaitList) {
                  <button class="btn transparent-button disabled-icon-button">
                    <i class="far fa-clock icon"></i>
                    {{ "APP.WAIT_LIST.JOINED_WAIT_LIST" | translate }}
                  </button>
                } @else if (event.canRsvp()) {
                  <div class="rsvp-navigate-wrapper">
                    <div class="header">
                      {{
                        event.actionSectionTitle ||
                          "APP.EVENT.RSVP_TO_THE_EVENT" | translate
                      }}
                    </div>
                    <button
                      class="btn transparent-button"
                      (click)="joinWaitlist()"
                    >
                      {{ "APP.WAIT_LIST.JOIN_WAIT_LIST" | translate }}
                    </button>
                  </div>
                }
              }
            }
          }
        }
      </div>

      <div class="party-info-container">
        @if (
          eventActionsSticky && (event.mainPictureUrl || visual.keyVisual1)
        ) {
          <div class="main-picture-cont">
            <div
              class="main-picture"
              [class.smaller-view]="orders && hasRequestedRefund()"
            >
              @if (event.isMainPictureVideo()) {
                <video
                  class="key-visual-1-video"
                  [muted]="'muted'"
                  autoplay
                  playsinline
                  loop
                >
                  <source [src]="event.mainPictureUrl" type="video/mp4" />
                </video>
              } @else {
                <img
                  class="key-visual-1"
                  [src]="event.mainPictureUrl || visual.keyVisual1?.url"
                />
              }
              @if (
                event.hasCampaignLogo() &&
                event.eventCampaign?.campaign?.logoUrl
              ) {
                <div>
                  <img
                    class="campaign-logo"
                    [src]="event.eventCampaign?.campaign?.logoUrl"
                  />
                </div>
              }
              @if (isCampaign && campaignData) {
                <app-campaign-ranking
                  [campaignData]="campaignData"
                  [event]="event"
                  [isLoggedUserHost]="
                    loggedUser &&
                    loggedUser.id &&
                    (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                  "
                />
              }
            </div>
          </div>
        }
        @if (event.subHeader && event.subHeader !== "") {
          <div class="invitation-text body-font">
            {{ event.subHeader }}
          </div>
        }

        <div class="event-content">
          <app-event-info [event]="event" [showHosts]="false" />
        </div>

        <div class="hosts-share-section">
          <div class="hosts-section">
            <i class="far fa-crown"></i>
            <app-event-hosts
              [event]="event"
              [isVotingView]="isVotingView"
            ></app-event-hosts>
          </div>
          @if (eventActionsSticky) {
            <app-share-buttons
              [event]="event"
              [isEventPage]="true"
              class="share-buttons-standalone"
            ></app-share-buttons>
          }
        </div>

        @if (eventActionsSticky && !isVotingView && !isCohostInvitation) {
          @if (event.hasApp(EventWidget.SELL_TICKETS)) {
            @if (isHostView || event.hasPublishedSellTickets()) {
              <div class="event-actions attendee-view">
                <div class="tickets-section">
                  @if (isHostView && !event.hasPublishedSellTickets()) {
                    <div
                      class="publish-sell-tickets-host-view"
                      (click)="onPublishSellTickets()"
                    >
                      <span>{{
                        "APP.TICKETING_SET_UP.PUBLISH_SELL_TICKETS" | translate
                      }}</span>
                      <i class="far fa-chevron-right"></i>
                    </div>
                  }
                  @if (orders) {
                    @if (hasRequestedRefund()) {
                      <div class="buy-tickets-wrapper one-row">
                        <div class="header">
                          <i class="far fa-redo"></i>
                          <div>
                            {{
                              "APP.REQUEST_REFUND.YOU_REQUESTED_REFUND"
                                | translate
                            }}
                          </div>
                        </div>
                        <button
                          class="btn btn-primary"
                          (click)="openRequestedRefundOverviewDialog()"
                        >
                          <i class="fas fa-chevron-right"></i>
                        </button>
                      </div>
                    }
                    <div class="event-actions">
                      <div
                        class="buy-tickets-wrapper"
                        [class.bought-ticket-wrapper]="orders"
                      >
                        <div class="header">
                          <i class="far fa-ticket"></i>
                          <div>
                            {{
                              "APP.REQUEST_REFUND.YOU_BOUGHT_TICKET" | translate
                            }}
                          </div>
                        </div>
                        <button
                          class="btn btn-primary"
                          (click)="openGetBoughtTicketsDialog()"
                        >
                          {{ "APP.REQUEST_REFUND.OPEN_TICKET" | translate }}
                        </button>
                        <div
                          class="header action"
                          (click)="openGetTicketsDialog()"
                        >
                          {{ "APP.EVENT.BUY_MORE_TICKETS" | translate }}
                          <i class="far fa-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  } @else {
                    <div class="buy-tickets-wrapper">
                      <div class="header">
                        <i class="far fa-shopping-cart"></i>
                        <div>
                          {{
                            event.actionSectionTitle ||
                              "APP.EVENT.BUY_TICKETS_FOR_THE_EVENT" | translate
                          }}
                        </div>
                      </div>
                      <button
                        class="btn btn-primary"
                        (click)="openGetTicketsDialog()"
                      >
                        {{ "APP.EVENT.GET_TICKETS_FROM" | translate }}
                        {{ event.getLowestTicketPriceDisplay() }}
                      </button>
                    </div>
                  }
                </div>
              </div>
            }
          } @else {
            @if (isAttendeeView) {
              @if (event.hasOneButtonSignUp() || event.hasTwoButtonSignUp()) {
                <div class="event-actions attendee-view">
                  <div class="share-button small" (click)="addToCalendar()">
                    <i class="far fa-calendar-day"></i>
                  </div>
                  <app-event-rsvp
                    [loggedUserId]="loggedUser?.id"
                    [isLoggedUserHost]="
                      loggedUser &&
                      loggedUser.id &&
                      (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                    "
                    [showGuestCount]="event.showGuestCount"
                    [isAttendeeView]="isAttendeeView"
                    [rsvpObservable$]="rsvpObservable$"
                    class="buttons-rsvp"
                  ></app-event-rsvp>
                  <div class="share-button small" (click)="shareEvent()">
                    <i class="far fa-share"></i>
                  </div>
                  <!--      <app-share-buttons
                          [event]="event"
                          [isEventPage]="true"
                        ></app-share-buttons>-->
                </div>
              } @else {
                <div class="event-actions attendee-view">
                  <div class="share-button" (click)="addToCalendar()">
                    <i class="far fa-calendar-day"></i>
                  </div>
                  <app-event-rsvp
                    [loggedUserId]="loggedUser?.id"
                    [isLoggedUserHost]="
                      loggedUser &&
                      loggedUser.id &&
                      (event.isUserHost(loggedUser.id) || loggedUser.isAdmin())
                    "
                    [showGuestCount]="event.showGuestCount"
                    [isAttendeeView]="isAttendeeView"
                    [rsvpObservable$]="rsvpObservable$"
                  ></app-event-rsvp>
                  <div class="share-button" (click)="shareEvent()">
                    <i class="far fa-share"></i>
                  </div>
                </div>
              }
            } @else if (!event.maxCapacityReached()) {
              @if (event.requireAttendeeApproval) {
                <div class="event-actions">
                  @if (userOnPendingList) {
                    <button class="btn transparent-button disabled-icon-button">
                      <i class="far fa-clock icon"></i>
                      {{ "APP.WAIT_LIST.PENDING_APPROVAL" | translate }}
                    </button>
                  } @else if (event.canRsvp()) {
                    <div class="rsvp-navigate-wrapper">
                      <div class="header">
                        {{
                          event.actionSectionTitle ||
                            "APP.EVENT.RSVP_TO_THE_EVENT" | translate
                        }}
                      </div>
                      <button
                        class="btn transparent-button"
                        (click)="getOnPendingList()"
                      >
                        {{ "APP.WAIT_LIST.GET_ON_THE_LIST" | translate }}
                      </button>
                    </div>
                  }
                </div>
              } @else if (event.canRsvp() || isUserHost) {
                <div class="event-actions">
                  @if (
                    event.hasOneButtonSignUp() || event.hasTwoButtonSignUp()
                  ) {
                    <div class="rsvp-navigate-wrapper">
                      <div class="header">
                        {{
                          event.actionSectionTitle ||
                            "APP.EVENT.RSVP_TO_THE_EVENT" | translate
                        }}
                      </div>
                      <app-event-rsvp
                        [loggedUserId]="loggedUser?.id"
                        [isLoggedUserHost]="
                          loggedUser &&
                          loggedUser.id &&
                          (event.isUserHost(loggedUser.id) ||
                            loggedUser.isAdmin())
                        "
                        [showGuestCount]="event.showGuestCount"
                        [rsvpObservable$]="rsvpObservable$"
                      ></app-event-rsvp>
                    </div>
                  } @else {
                    <app-event-rsvp
                      [loggedUserId]="loggedUser?.id"
                      [isLoggedUserHost]="
                        loggedUser &&
                        loggedUser.id &&
                        (event.isUserHost(loggedUser.id) ||
                          loggedUser.isAdmin())
                      "
                      [showGuestCount]="event.showGuestCount"
                      [rsvpObservable$]="rsvpObservable$"
                    ></app-event-rsvp>
                  }
                </div>
              }
            } @else if (event.maxCapacityReached() && event.waitlistEnabled) {
              @if (userOnWaitList) {
                <div class="event-actions">
                  <button class="btn transparent-button disabled-icon-button">
                    <i class="far fa-clock icon"></i>
                    {{ "APP.WAIT_LIST.JOINED_WAIT_LIST" | translate }}
                  </button>
                </div>
              } @else if (event.canRsvp()) {
                <div class="event-actions">
                  <div class="rsvp-navigate-wrapper">
                    <div class="header">
                      {{
                        event.actionSectionTitle ||
                          "APP.EVENT.RSVP_TO_THE_EVENT" | translate
                      }}
                    </div>
                    <button
                      class="btn transparent-button"
                      (click)="joinWaitlist()"
                    >
                      {{ "APP.WAIT_LIST.JOIN_WAIT_LIST" | translate }}
                    </button>
                  </div>
                </div>
              }
            }
          }
        }

        @if (!isServer && widgets && widgets.length > 0 && !isVotingView) {
          <div class="toggle-container">
            <div
              class="toggle-button"
              [class.active]="!selectedTab"
              (click)="selectTab(undefined)"
            >
              {{ "APP.EVENT.DETAILS" | translate }}
            </div>
            @if (widgets.includes(EventWidget.COMMENTS)) {
              <div
                class="toggle-button"
                [class.active]="selectedTab === EventWidget.COMMENTS"
                (click)="selectTab(EventWidget.COMMENTS)"
              >
                {{ "APP.COMMENTS.TITLE" | translate }}
              </div>
            }
          </div>
          @if (!selectedTab) {
            <app-event-details
              [event]="event"
              [isDesktop]="isDesktop"
            ></app-event-details>
          } @else if (
            widgets.includes(EventWidget.COMMENTS) &&
            selectedTab === EventWidget.COMMENTS
          ) {
            <app-event-comments
              [loggedUser]="loggedUser"
              (publishEvent)="onPublishEvent()"
              (rsvp)="onRsvp()"
              (useTemplate)="onUseTemplate()"
            ></app-event-comments>
          }
        } @else {
          <div class="event-details-only">
            <app-event-details
              [event]="event"
              [isDesktop]="isDesktop"
            ></app-event-details>
          </div>
        }
      </div>
    </div>

    <app-footer
      class="after-footer"
      [isEventFooter]="true"
      [showLogo]="true"
    ></app-footer>
  </div>

  <div
    class="background-overlay"
    [class.host-view]="isHostView"
    [style.backgroundColor]="
      visual.backgroundColor ? visual.backgroundColor : ''
    "
    [style.background-image]="
      visualBackgroundUrl && visual.isBackgroundImage()
        ? 'url(' + visualBackgroundUrl + ')'
        : ''
    "
  >
    @if (visualBackgroundUrl && visual.isBackgroundVideo()) {
      <div class="background-video-cont">
        <video
          #backgroundVideo
          class="background-video"
          [muted]="'muted'"
          playsinline
          loop
          preload="metadata"
        >
          @if (visualBackgroundHevcUrl !== "") {
            <source
              [src]="visualBackgroundHevcUrl + '#t=0.001'"
              type="video/mp4;codecs=hvc1"
            />
          }
          <source
            [src]="visualBackgroundUrl"
            [type]="visual.background?.mimeType || 'video/mp4'"
          />
        </video>
      </div>
    }
  </div>

  @if (visualEffectUrl) {
    <div
      class="effect-overlay"
      [style.background-image]="
        visualEffectUrl && visual.isEffectImage()
          ? 'url(' + visualEffectUrl + ')'
          : ''
      "
      [class.host-view]="isHostView"
      [class.one-time-effect]="!visual.effect?.repeat"
    >
      @if (!visual.isEffectImage()) {
        <video
          #effectVideo
          class="effect-video"
          [muted]="'muted'"
          playsinline
          [loop]="visual.effect?.repeat"
          preload="metadata"
        >
          @if (visualEffectHevcUrl !== "") {
            <source
              [src]="visualEffectHevcUrl + '#t=0.001'"
              type="video/mp4;codecs=hvc1"
            />
          }
          <source
            [src]="visualEffectUrl"
            [type]="visual.effect?.mimeType || 'video/mp4'"
          />
        </video>
      }
    </div>
  }
}
