import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { EventRSVPOption } from '@models/event-attendance/event-rsvp-option.model';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { AttendeeListDialog } from '@modules/events/dialogs/attendee-rsvp/attendee-list/attendee-list.dialog';
import { EventAfterAttendOverlayDialogComponent } from '@modules/events/dialogs/event-after-attend-overlay-dialog/event-after-attend-overlay-dialog.component';
import { RsvpDialog } from '@modules/events/dialogs/rsvp/rsvp.dialog';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { TranslateModule } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { EventStore } from '@services/stores/event.store';
import { Helpers } from '@utils/helpers';
import { FormsModules } from '@utils/shared-modules';
import { TextEditableDirective } from 'app/directives/text-editable.directive';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { TextSizeDirective } from '../../../../directives/text-size.directive';
import { Observable, Subject, takeUntil } from 'rxjs';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';

@Component({
  selector: 'app-event-rsvp',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    NgxTippyModule,
    UserAvatarComponent,
    PickerComponent,
    TextEditableDirective,
    TextSizeDirective,
  ],
  providers: [EventService],
  templateUrl: './event-rsvp.component.html',
  styleUrl: './event-rsvp.component.scss',
})
export class EventRsvpComponent implements OnInit, OnDestroy {
  event?: Event;
  @Input() editMode = false;
  @Input() loggedUserId?: number;
  @Input({ transform: booleanAttribute }) isLoggedUserHost: boolean = true;
  @Input() showGuestCount?: boolean;
  @Input() isAttendeeView?: boolean;
  @Input() showGuestCountForHost?: boolean = true;
  @Input() rsvpObservable$?: Observable<boolean>;

  yesAttendees?: EventAttendee[];
  noAttendees?: EventAttendee[];
  maybeAttendees?: EventAttendee[];

  showEmojiPicker = false;
  currentEditType?: RSVPOptionType;

  attendeesDialogRef?: MatDialogRef<AttendeeListDialog>;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private eventService: EventService,
    private eventStore: EventStore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.eventStore.event.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event) {
        this.event = event;
      }
    });
  }

  ngOnInit(): void {
    if (this.event) {
      this.yesAttendees = this.event.getAttendees(RSVPOptionType.YES);
      this.noAttendees = this.event.getAttendees(RSVPOptionType.NO);
      this.maybeAttendees = this.event.getAttendees(RSVPOptionType.MAYBE);

      this.checkOpenRsvp();
    }
    if (this.rsvpObservable$) {
      this.rsvpObservable$
        .pipe(takeUntil(this._onDestroy))
        .subscribe((rsvp) => {
          if (rsvp) {
            this.onRSVP(RSVPOptionType.YES);
          }
        });
    }
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  onRSVP(type: RSVPOptionType) {
    if (this.editMode) {
      return;
    }

    let notGoing = false;
    if (
      this.event?.type === EventType.SPORTPAGE ||
      this.event?.hasOneButtonSignUp()
    ) {
      if (type === RSVPOptionType.NO) {
        notGoing = true;
      }
    }

    if (
      this.event?.hasTwoButtonSignUp() &&
      type === RSVPOptionType.YES &&
      this.isAttendeeView
    ) {
      notGoing = true;
    }

    const dialogRef = this.dialog.open(RsvpDialog, {
      viewContainerRef: this.viewContainerRef,
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        event: this.event,
        rsvpType: type,
        isAttendee: !!this.userAttendeeRSVPOption,
        notGoing: notGoing,
        onWaitlist:
          this.event?.maxCapacityReached() && this.event.waitlistEnabled,
      },
      panelClass: ['normal-dialog', 'rsvp-dialog', 'overlay-theme'],
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        if (this.event.isPreviewEvent) {
          return;
        }

        if (res.refresh) {
          this.eventStore.refreshEvent(this.event.uri);
        }

        if (res.openEventAfterAttendOverlayDialog) {
          this.dialog.open(EventAfterAttendOverlayDialogComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            panelClass: 'overlay-page-dialog',
            data: {
              event: this.event,
              rsvpType: res.rsvpType || type,
              onWaitlist:
                this.event?.maxCapacityReached() && this.event.waitlistEnabled,
            },
          });
        }
      } else {
        if (this.event) {
          this.eventStore.refreshEvent(this.event.uri);
        }
      }
    });
  }

  viewAttendeeList(type: RSVPOptionType) {
    if (
      (this.attendeesDialogRef &&
        this.attendeesDialogRef.getState() !== MatDialogState.CLOSED) ||
      (this.event && !this.event.showGuestList)
    ) {
      return;
    }

    this.attendeesDialogRef = this.dialog.open(AttendeeListDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      autoFocus: false,
      disableClose: true,
      data: {
        event: this.event,
        rsvpType: type,
      },
      panelClass: ['normal-dialog', 'event-dialog'],
    });

    this.attendeesDialogRef.afterClosed().subscribe((result) => {
      if (result.refresh) {
        this.yesAttendees = result?.yesAttendees || [];
        this.noAttendees = result?.noAttendees || [];
        this.maybeAttendees = result?.maybeAttendees || [];
      }
    });
  }

  openEmojiPicker(type: RSVPOptionType) {
    if (!this.editMode) {
      return;
    }
    this.showEmojiPicker = true;
    this.currentEditType = type;
  }

  changeEmoji(event: any) {
    const rsvpOption = this.event?.rsvpOptions?.find(
      (rsvpOption) => rsvpOption.type === this.currentEditType,
    );
    if (rsvpOption) {
      rsvpOption.emoji = event.emoji.native;

      if (rsvpOption.id) {
        this.updateRSVP({
          id: rsvpOption.id,
          eventId: this.event?.id,
          type: rsvpOption.type,
          emoji: rsvpOption.emoji,
        });
      } else {
        this.updateRSVP({
          eventId: this.event?.id,
          type: rsvpOption.type,
          name: rsvpOption.name,
          emoji: rsvpOption.emoji,
        });
      }
    }

    this.showEmojiPicker = false;
  }

  onRSVPOptionNameChanged(val: any, rsvpOption: EventRSVPOption) {
    rsvpOption.name = val;

    if (rsvpOption.id) {
      this.updateRSVP({
        id: rsvpOption.id,
        eventId: this.event?.id,
        name: rsvpOption.name,
        type: rsvpOption.type,
      });
    } else {
      this.updateRSVP({
        eventId: this.event?.id,
        type: rsvpOption.type,
        name: rsvpOption.name,
        emoji: rsvpOption.emoji,
      });
    }
  }

  updateRSVP(rsvpOption: any) {
    const req = {
      rsvpOptions: [rsvpOption],
      newUser: {
        eventUuid: this.event?.uuid,
      },
    };

    this.eventService.updateEvent(this.event?.id, req).subscribe((event) => {
      this.event = event;
      this.eventStore.setEvent(event);
    });
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get userAttendeeRSVPOption(): EventRSVPOption | undefined {
    return this.loggedUserId
      ? this.event?.getUserAttendeeRSVPOption(this.loggedUserId)
      : undefined;
  }

  getAttendees(
    type: RSVPOptionType,
    forList = false,
  ): EventAttendee[] | undefined {
    let attendees: EventAttendee[] | undefined = [];
    switch (type) {
      case RSVPOptionType.YES:
        attendees = this.yesAttendees;
        break;
      case RSVPOptionType.NO:
        attendees = this.noAttendees;
        break;
      case RSVPOptionType.MAYBE:
        attendees = this.maybeAttendees;
        break;
    }

    if (forList && attendees && attendees.length > 3) {
      return attendees.slice(0, 3);
    }

    return attendees;
  }

  getAttendeesLength(type: RSVPOptionType): number {
    const attendees = this.getAttendees(type);

    return attendees ? attendees.length : 0;
  }

  get yesRSVPOption(): EventRSVPOption | undefined {
    return this.event?.getYesRSVPOption();
  }

  get noRSVPOption(): EventRSVPOption | undefined {
    return this.event?.getNoRSVPOption();
  }

  get currentUserRSVP(): RSVPOptionType | null {
    const rsvpType = this.event?.attendees?.find(
      (x) => x.userId === this.loggedUserId,
    )?.rsvpType;
    if (rsvpType) {
      return rsvpType;
    }
    return null;
  }

  get isOnWaitlist(): EventAttendeeStatus | null {
    const status = this.event?.attendees?.find(
      (x) =>
        x.userId === this.loggedUserId &&
        x.status === EventAttendeeStatus.ON_WAITLIST,
    )?.status;
    if (status) {
      return status;
    }
    return null;
  }

  get isUaEvent(): boolean {
    return !!this.event?.uri.includes('underarmour-infinite-elite-testival');
  }

  @HostListener('document:click', ['$event'])
  public onGlobalClick(event: MouseEvent) {
    let path = event.composedPath();
    let emojiPanel = document.getElementById('emoji-palette'); // this is emoji panel element
    let emojiButtons = document.getElementsByClassName('rsvp-emoji'); //this is button to trigger the emoji panel

    let pathIncludesEmojiButton = false;

    for (let i = 0; i < emojiButtons.length; i++) {
      // @ts-ignore
      if (path.includes(emojiButtons.item(i))) {
        pathIncludesEmojiButton = true;
        break;
      }
    }

    if (emojiPanel && !pathIncludesEmojiButton) {
      if (!path.includes(emojiPanel)) {
        this.showEmojiPicker = false;
      }
    }
  }

  private checkOpenRsvp() {
    const snapshot = this.activatedRoute.snapshot;

    if (snapshot.paramMap.has('uri')) {
      const uri = snapshot.paramMap.get('uri');
      const rsvp = snapshot.queryParamMap.get('rsvp');

      if (rsvp && rsvp !== '') {
        const rsvpGoingOrNot = rsvp === 'true';

        if (uri && uri !== '') {
          if (this.activatedRoute.snapshot.queryParamMap) {
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {
                rsvp: null,
              },
              queryParamsHandling: 'merge',
            });
          }

          if (rsvpGoingOrNot) {
            this.onRSVP(RSVPOptionType.YES);
          } else {
            this.onRSVP(RSVPOptionType.NO);
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getTextColorForBg = Helpers.getTextColorForBg;
  protected readonly EventType = EventType;
  protected readonly RSVPOptionType = RSVPOptionType;
  protected readonly EventAttendeeStatus = EventAttendeeStatus;
}
