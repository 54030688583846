import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { EventAttendeeTicketOrderStatus } from '@models/events/event-attendee-ticket-order-status.enum';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { EventAttendeeTicket } from '@models/events/event-attendee-ticket.model';
import { EventAttendeeTicketOrderRefundStatus } from '@models/events/event-attendee.ticket-order-refund-status.enum';
import { Event } from '@models/events/event.model';
import { User } from '@models/users/user.model';
import { TicketActionComponent } from '@modules/events/dialogs/get-bought-tickets/components/ticket-action/ticket-action.component';
import { QrDataCardComponent } from '@modules/shared/components/qr-data-card/qr-data-card.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { TicketAction } from '@models/tickets/ticket-action.enum';
import { environment as env } from '@environments/environment';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '../../../../directives/swiper.directive';

@Component({
  selector: 'app-requested-refund-overview.dialog',
  standalone: true,
  imports: [
    TranslateModule,
    MatDialogActions,
    TicketActionComponent,
    QrDataCardComponent,
    MatDialogContent,
    SwiperDirective,
  ],
  templateUrl: './requested-refund-overview.dialog.html',
  styleUrl: './requested-refund-overview.dialog.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [EventService, NotificationService, TranslateService],
})
export class RequestedRefundOverviewDialog {
  tickets: EventAttendeeTicket[] = [];
  loggedUser?: User;
  event?: Event;
  orders?: EventAttendeeTicketOrder[];
  @ViewChild('swiperContainer') swiperContainer!: any;
  @ViewChild('swiperRef', { static: false }) swiperRef: any;
  @ViewChild('swiperElement') swiperElement: any;

  swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    speed: 2000,
    spaceBetween: 16,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
    },
    injectStyles: [
      `  :host .swiper { overflow: visible;  min-height: 500px;}
      .swiper-wrapper {
        min-height: 500px !important;
      }
      .swiper-pagination-bullets-dynamic {
        bottom: 15px !important;
      }
    `,
    ],
  };
  constructor(
    public dialogRef: MatDialogRef<RequestedRefundOverviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    if (data) {
      this.event = data.event;
      if (data.orders) {
        this.orders = data.orders;
      }
      this.loggedUser = data.loggedUser;
    }
  }

  getHeaderText(order: EventAttendeeTicketOrder) {
    return `${this.translateService.instant(
      'APP.REQUEST_REFUND.ORDER_NUMBER',
    )}: ${order?.orderNumber} (${order?.eventAttendeeTickets
      .length}) ${this.translateService.instant('APP.REQUEST_REFUND.TICKETS')}`;
  }

  contactHost() {
    let email = this.event?.hostUser?.email;
    // TODO: Per Anhalter email hardcoded, use support email
    if (!email || email === '') {
      email = 'peranhalter@eventpage.ai';
    }
    if (email) {
      const mailtoLink = `mailto:${email}`;
      window.location.href = mailtoLink;
    }
  }

  executeAction(action: TicketAction, order: EventAttendeeTicketOrder) {
    switch (action) {
      case TicketAction.CANCEL_REFUND_REQUEST:
        this.cancelRefund(order);
        break;
      case TicketAction.DOWNLOAD:
        this.downloadRefundInvoice(order);
        break;
    }
  }

  downloadRefundInvoice(order: EventAttendeeTicketOrder) {
    if (order?.refund) {
      this.eventService
        .downloadTicketOrderRefundPdf(order.refund.id)
        .subscribe({
          error: () => {
            this.notificationService.error(
              this.translateService.instant(
                'APP.EVENT_PAYMENT.COULD_NOT_DOWNLOAD',
              ),
            );
          },
        });
    }
  }

  cancelRefund(order: EventAttendeeTicketOrder) {
    if (order?.refund) {
      this.eventService.cancelTicketOrderRefund(order?.refund?.id).subscribe({
        next: (res) => {
          this.notificationService.success(
            this.translateService.instant(
              'APP.REFUND_REASONS.SUCCESSFULLY_CANCELLED_REFUND',
            ),
          );
          this.close(true);
        },
      });
    }
  }

  close(refresh = false) {
    this.dialogRef.close({ refresh });
  }

  protected env = env;
  protected readonly TicketAction = TicketAction;
  protected readonly EventAttendeeTicketOrderStatus =
    EventAttendeeTicketOrderStatus;
  protected readonly EventAttendeeTicketOrderRefundStatus =
    EventAttendeeTicketOrderRefundStatus;
}
