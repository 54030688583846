<div class="event-hosts">
  @if (event && font) {
    @if (!multipleHosts && !editMode) {
      <div class="single-host">
        <app-user-avatar
          [user]="event.hostUser"
          [size]="avatarSize"
          [isVotingView]="isVotingView"
          [placeholderFontSize]="avatarPlaceholderFontSize"
          [backgroundColor]="scrollView ? 'var(--bg-tertiary)' : undefined"
        ></app-user-avatar>
        <div class="host-name body-font">
          @if (isVotingView) {
            {{ event.hostUser?.getInitials() }}
          } @else {
            {{ event.hostUser?.name }}
          }
        </div>
      </div>
    } @else if (multipleHosts || editMode) {
      @if (scrollView) {
        <div class="multiple-scroll-wrapper">
          <div class="single-host">
            <app-user-avatar
              [user]="event.hostUser"
              [size]="avatarSize"
              [isVotingView]="isVotingView"
              [placeholderFontSize]="avatarPlaceholderFontSize"
              backgroundColor="var(--bg-tertiary)"
            ></app-user-avatar>
            <div class="host-name body-font">
              @if (isVotingView) {
                {{ event.hostUser?.getInitials() }}
              } @else {
                {{ event.hostUser?.name }}
              }
            </div>
          </div>
          @for (host of event.hosts; track host.id) {
            <div class="single-host">
              <app-user-avatar
                [user]="host.user"
                [size]="avatarSize"
                [nameTooltip]="true"
                [style.height.px]="avatarSize"
                [placeholderFontSize]="avatarPlaceholderFontSize"
                [isVotingView]="isVotingView"
                [fallbackInitials]="host.getFallbackInitials()"
                backgroundColor="var(--bg-tertiary)"
              ></app-user-avatar>
              <div class="host-name body-font">
                @if (isVotingView) {
                  {{ host.user?.getInitials() }}
                } @else {
                  {{ host.user?.name }}
                }
              </div>
            </div>
          }
        </div>
      } @else {
        <div class="multiple-hosts">
          <div class="hosts">
            <div class="event-host">
              <app-user-avatar
                [user]="event.hostUser"
                [size]="avatarSize"
                [nameTooltip]="true"
                [isVotingView]="isVotingView"
                [placeholderFontSize]="avatarPlaceholderFontSize"
              ></app-user-avatar>
            </div>
            @for (host of event.hosts; track host.id) {
              @if (editMode || host.status === "ACTIVE") {
                <div class="event-host">
                  @if (editMode) {
                    @if (host.status === "INVITED") {
                      <i class="fa fa-clock host-invite-pending-icon"></i>
                    } @else {
                      <div
                        class="remove-btn"
                        [style.width.px]="avatarSize / 2"
                        [style.height.px]="avatarSize / 2"
                        [style.fontSize.px]="avatarPlaceholderFontSize - 2"
                        (click)="removeHost(host)"
                      >
                        <i class="far fa-times"></i>
                      </div>
                    }
                  }

                  <app-user-avatar
                    [user]="host.user"
                    [size]="avatarSize"
                    [nameTooltip]="true"
                    [style.height.px]="avatarSize"
                    [placeholderFontSize]="avatarPlaceholderFontSize"
                    [isVotingView]="isVotingView"
                    [fallbackInitials]="host.getFallbackInitials()"
                  ></app-user-avatar>
                </div>
              }
            }
            @if (editMode) {
              <div class="event-host add-host" (click)="addHost()">
                <div
                  class="add-host-placeholder"
                  [style.width.px]="avatarSize"
                  [style.height.px]="avatarSize"
                >
                  <i
                    class="far fa-plus"
                    [style.fontSize.px]="avatarPlaceholderFontSize"
                  ></i>
                </div>
              </div>
            }
          </div>
        </div>
      }
    }
  }
</div>
