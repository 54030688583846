export class QuillUtils {
  static replaceEmptyPTags(html: string): string {
    return html.replace(/<p[^>]*>\s*<\/p>/g, '<br/>');
  }

  static replaceSpacesWithNbsp(htmlString: string | null | undefined): string {
    if (htmlString == null) {
      return '';
    }

    return htmlString.replace(/(<[^>]*>)| /g, (match, tag) =>
      tag ? tag : '&nbsp;',
    );
  }

  static isContentEmpty(htmlContent: string): boolean {
    if (!htmlContent) return true;

    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlContent;

    return tempElement.textContent?.trim() === '';
  }
}
