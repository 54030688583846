import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import {
  CreateEventTicketOrderSessionRequest,
  CreateEventTicketOrderSessionTicket,
} from '@models/events/dto/create-event-ticket-order-session.request';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { EventAttendeeTicket } from '@models/events/event-attendee-ticket.model';
import { EventTicket } from '@models/events/event-ticket.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { TicketVoucherCodeResponse } from '@models/tickets/dto/ticket-voucher-code.response';
import { EventPaymentComponent } from '@modules/events/components/event-payment/event-payment.component';
import { EventTicketSelectionViewComponent } from '@modules/events/components/event-ticket-selection-view/event-ticket-selection-view.component';
import { TicketPaymentSuccessComponent } from '@modules/events/components/ticket-payment-success/ticket-payment-success.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { TicketService } from '@services/tickets/ticket.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { NumberUtils } from '@utils/number-utils';
import { FormsModules } from '@utils/shared-modules';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EventAttendeeTicketOrderStatus } from '@models/events/event-attendee-ticket-order-status.enum';
import { StripeError } from '@stripe/stripe-js';

@Component({
  selector: 'app-event-generic-buying-tickets',
  standalone: true,
  imports: [
    CommonModule,
    EventTicketSelectionViewComponent,
    MatExpansionModule,
    TranslateModule,
    MatFormField,
    FormsModules,
    MatTooltip,
    MatProgressSpinnerModule,
    EventPaymentComponent,
    TicketPaymentSuccessComponent,
  ],
  templateUrl: './event-generic-buying-tickets.component.html',
  styleUrl: './event-generic-buying-tickets.component.scss',
})
export class EventGenericBuyingTicketsComponent implements OnInit {
  private _existingTicketOrder?: EventAttendeeTicketOrder;
  @Input() isPreview: boolean = false;
  @Input() tickets?: EventTicket[];
  @Input() event?: Event;
  @Input() mainImage?: string;
  @Input() paymentRedirectStatus?: string;
  @Input() set existingTicketOrder(
    existingTicketOrder: EventAttendeeTicketOrder | undefined,
  ) {
    if (existingTicketOrder) {
      if (this.paymentRedirectStatus === 'failed') {
        existingTicketOrder.status =
          EventAttendeeTicketOrderStatus.REQUIRES_PAYMENT_METHOD;
        // this.onPaymentFailed();
      } else if (
        existingTicketOrder.isPaidOrProcessing() ||
        this.paymentRedirectStatus === 'succeeded'
      ) {
        if (this.paymentRedirectStatus === 'succeeded') {
          existingTicketOrder.status = EventAttendeeTicketOrderStatus.SUCCEEDED;
        }
        this.ticketOrder = existingTicketOrder;
        this.showSuccessPage = true;
      }
    }
  }
  get existingTicketOrder(): EventAttendeeTicketOrder | undefined {
    return this._existingTicketOrder;
  }

  @Output() paymentFailed = new EventEmitter<StripeError | undefined>();

  isDesktop?: boolean;
  form?: FormGroup;
  fullPrice: number = 0;
  orderTickets: CreateEventTicketOrderSessionTicket[] = [];
  filteredTickets?: EventTicket[] = [];
  voucherApplied?: TicketVoucherCodeResponse;
  ticketsWithAppliedDiscount: number[] = [];
  unrecognizedCode?: string;
  currentTicketQuantity: number = 0;
  ticketOrder?: EventAttendeeTicketOrder;
  ticketQuantityMap?: {
    ticket: EventAttendeeTicket;
    quantity: number;
  }[];
  isLoading: boolean = false;
  showSuccessPage: boolean = false;
  isAddingFirstTime: boolean = true;
  updatedSessionRequest?: CreateEventTicketOrderSessionRequest;
  hasPublicCode: boolean = false;
  noSessionTickets: boolean = true;
  currentLang?: string;

  readonly panelOpenState = signal(true);
  readonly applyVoucherOpenState = signal(false);
  readonly ticketsOpenState = signal(true);

  constructor(
    private translateService: TranslateService,
    private screenWidthService: ScreenWidthService,
    private fb: FormBuilder,
    private ticketService: TicketService,
    private eventService: EventService,
    private notificationService: NotificationService,
  ) {
    this.form = this.fb.group({
      voucherCode: [null],
    });
    this.form
      .get('voucherCode')
      ?.valueChanges.pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.unrecognizedCode = undefined;
      });
    this.trackIsDesktopChange();
    this.translateService.onLangChange
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.currentLang = this.translateService.currentLang;
      });
    this.currentLang = this.translateService.currentLang;
  }

  private trackIsDesktopChange() {
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe((isDesktop) => {
        this.isDesktop = isDesktop;
      });
  }

  toggleTicketsParent() {
    this.panelOpenState.set(true);
    if (!this.ticketsOpenState()) {
      this.applyVoucherOpenState.set(false);
    }
  }

  ngOnInit() {
    this.setFilteredTickets();
    this.getPublicCode();
  }

  onPaymentSuccess(ticketOrder: EventAttendeeTicketOrder) {
    this.ticketOrder = ticketOrder;
    this.showSuccessPage = true;
    this.scrollToTop();
  }
  onPaymentFailed(err?: StripeError) {
    this.paymentFailed.emit(err);
  }
  togglePanelState(event: any): void {
    event.stopPropagation();
    let tickets: CreateEventTicketOrderSessionTicket[] = [];
    this.orderTickets
      .filter((order) => order.quantity !== 0)
      .forEach((x) => tickets.push(x));
    if (tickets.length < 1) {
      this.isLoading = false;
      this.ticketQuantityMap = undefined;
      this.panelOpenState.set(!this.panelOpenState());
      this.ticketsOpenState.set(!this.ticketsOpenState());
      if (!this.panelOpenState()) {
        this.applyVoucherOpenState.set(false);
      }
      return;
    } else {
      this.isLoading = true;
      this.panelOpenState.set(!this.panelOpenState());
      this.ticketsOpenState.set(!this.ticketsOpenState());
      if (!this.panelOpenState()) {
        this.applyVoucherOpenState.set(false);
      }
    }
  }

  calculateDiscount(ticket: EventTicket, quantity?: number) {
    let price = ticket?.price ?? 0;
    if (this.voucherApplied) {
      if (this.voucherApplied.discountFixedPrice) {
        price -= this.voucherApplied.discountFixedPrice;
        if (price < 0) {
          price = 0;
        }
      } else {
        const discountPercentage = this.voucherApplied.discountPercentage ?? 0;
        price *= 1 - discountPercentage / 100;
      }
    }
    return quantity
      ? `${NumberUtils.roundToUpTo2Decimals((price * quantity) / 100)} €`
      : `${NumberUtils.roundToUpTo2Decimals(price / 100)} €`;
  }

  createOrderTicketsMap(): Map<
    EventTicket,
    CreateEventTicketOrderSessionTicket[]
  > {
    const ticketsMap = new Map<
      EventTicket,
      CreateEventTicketOrderSessionTicket[]
    >();

    if (!this.tickets) {
      return ticketsMap;
    }

    this.orderTickets
      .filter((orderTicket) => orderTicket.quantity > 0) // Filter orderTickets with quantity > 0
      .forEach((orderTicket) => {
        const ticket = this.tickets?.find(
          (t) => t.id === orderTicket.eventTicketId,
        );
        if (ticket) {
          if (!ticketsMap.has(ticket)) {
            ticketsMap.set(ticket, []);
          }
          ticketsMap.get(ticket)!.push(orderTicket);
        }
      });

    return ticketsMap;
  }

  applyCode() {
    if (this.form && this.form.valid && this.event) {
      const voucherCode = this.form.controls['voucherCode'].value;
      this.ticketService.getVoucherCode(voucherCode, this.event?.id).subscribe({
        next: (result) => {
          this.currentTicketQuantity = 0;
          this.voucherApplied = result;
          this.form?.get('voucherCode')?.disable();
          if (!this.panelOpenState()) {
            this.createTicketOrderSession();
          }
          if (result) {
            this.form?.controls['voucherCode'].setValue(result.codeName);
            this.ticketsWithAppliedDiscount = [];

            if (result.tickets && result.tickets.length > 0) {
              this.ticketsWithAppliedDiscount = result.tickets
                .filter((x) => x.eventTicketId !== undefined)
                .map((x) => x.eventTicketId as number);
            }

            if (this.filteredTickets && result.limit) {
              for (let ticket of this.filteredTickets) {
                const existingTicketIdx = this.orderTickets.findIndex(
                  (orderTicket) => orderTicket.eventTicketId === ticket.id,
                );
                if (existingTicketIdx !== -1) {
                  let existingTicket = this.orderTickets[existingTicketIdx];
                  if (this.checkIfDiscountApplied(ticket)) {
                    const remainingLimit =
                      result.limit - this.currentTicketQuantity;
                    if (remainingLimit > 0) {
                      if (
                        remainingLimit < ticket.minAmountPerOrder ||
                        remainingLimit === ticket.minAmountPerOrder
                      ) {
                        this.orderTickets[existingTicketIdx].quantity = 0;
                      } else if (existingTicket.quantity > remainingLimit) {
                        this.currentTicketQuantity = result.limit;
                        this.orderTickets[existingTicketIdx].quantity =
                          remainingLimit;
                      } else {
                        this.currentTicketQuantity += existingTicket.quantity;
                      }
                    } else {
                      this.orderTickets[existingTicketIdx].quantity = 0;
                    }
                  }
                }
              }
            }
            this.calculateFullPrice();
            this.updatedSessionRequest = this.createSessionRequest();
          }
        },
        error: () => {
          this.unrecognizedCode = voucherCode;
        },
      });
    } else {
      this.form?.markAsTouched();
    }
  }
  getPublicCode() {
    if (this.event) {
      this.ticketService.getPublicVoucherCode(this.event?.id).subscribe({
        next: (result) => {
          if (result) {
            this.hasPublicCode = true;
            this.voucherApplied = result;
            this.applyVoucherOpenState.set(true);
            this.form?.controls['voucherCode'].setValue(result.codeName);
            this.form?.get('voucherCode')?.disable();
            if (result.tickets && result.tickets.length > 0) {
              this.ticketsWithAppliedDiscount = result.tickets
                .filter((x) => x.eventTicketId !== undefined)
                .map((x) => x.eventTicketId as number);
            }
          }
        },
        error: () => {},
      });
    }
  }

  setFilteredTickets() {
    const currentDate = new Date();
    this.filteredTickets = this.tickets
      ?.filter(
        (ticket) => !ticket.saleEndDate || ticket.saleEndDate >= currentDate,
      )
      .slice()
      .sort((a, b) => {
        const isASoldOut = this.checkIfSoldOut(a);
        const isBSoldOut = this.checkIfSoldOut(b);

        if (isASoldOut && !isBSoldOut) {
          return 1;
        } else if (!isASoldOut && isBSoldOut) {
          return -1;
        } else {
          return 0;
        }
      });
  }

  createSessionRequest() {
    const request: CreateEventTicketOrderSessionRequest = {
      tickets: this.orderTickets,
    };

    this.noSessionTickets =
      !this.orderTickets ||
      this.orderTickets.every((ticket) => ticket.quantity === 0);

    if (this.voucherApplied) {
      request.ticketVoucherCode = this.voucherApplied.codeName;
    } else {
      request.ticketVoucherCode = undefined;
    }
    return request;
  }

  checkIfNoTickets(): boolean {
    return (
      !this.orderTickets ||
      this.orderTickets.every((ticket) => ticket.quantity === 0)
    );
  }

  createTicketOrderSession() {
    if (!this.event || this.orderTickets.length < 1) {
      return;
    }

    this.eventService
      .createEventTicketOrderSession(this.event.id, this.createSessionRequest())
      .subscribe({
        next: (ticketOrder) => {
          this.ticketOrder = ticketOrder;
          if (ticketOrder) {
            this.ticketQuantityMap = this.ticketOrder?.getTicketQuantityMap();
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.notificationService.error(
            this.translateService.instant(
              'APP.TICKETING.ERRORS.COULD_NOT_CREATE_ORDER',
            ),
          );
        },
      });
  }

  removeCode() {
    this.voucherApplied = undefined;
    this.currentTicketQuantity = 0;
    this.form?.get('voucherCode')?.enable();
    this.form?.controls['voucherCode'].setValue(undefined);
    if (!this.panelOpenState()) {
      this.createTicketOrderSession();
    }
    this.calculateFullPrice();
    this.updatedSessionRequest = this.createSessionRequest();
    this.checkIfNoTickets();
  }

  checkIfDiscountApplied(ticket: EventTicket) {
    if (this.voucherApplied) {
      if (this.voucherApplied?.tickets?.length === 0) {
        if (!ticket.price || ticket.price === 0) {
          return false;
        }
        return true;
      } else {
        return !!(
          ticket?.id !== undefined &&
          this.ticketsWithAppliedDiscount?.includes(ticket.id) &&
          ticket.price !== 0
        );
      }
    } else {
      return false;
    }
  }
  calculateFullPrice() {
    // let remainingLimit = this.voucherApplied?.limit ?? 0;

    this.fullPrice = this.orderTickets
      .map((orderTicket) => {
        let applicableQuantity = orderTicket.quantity;

        let price = orderTicket.price ?? 0;
        const ticket = this.filteredTickets?.find(
          (ticket) => ticket.id === orderTicket.eventTicketId,
        );
        if (ticket && ticket.price === 0) {
          return 0;
        }

        if (
          this.voucherApplied &&
          (this.ticketsWithAppliedDiscount?.length === 0 ||
            this.ticketsWithAppliedDiscount?.includes(
              orderTicket.eventTicketId,
            ))
        ) {
          // applicableQuantity = Math.min(orderTicket.quantity, remainingLimit);
          //remainingLimit -= applicableQuantity;
          if (this.voucherApplied.discountFixedPrice) {
            price = price - this.voucherApplied.discountFixedPrice;
            if (price < 0) {
              price = 0;
            }
          }
          if (this.voucherApplied.discountPercentage) {
            price =
              price * ((100 - this.voucherApplied.discountPercentage) / 100);
          }
        }

        return (price && price > 0 ? price : 0) * applicableQuantity;
      })
      .reduce((a, b) => a + b, 0);

    if (this.ticketOrder) {
      if (!this.ticketOrder.hasPaymentIntent() && this.fullPrice >= 50) {
        this.createTicketOrderSession();
      } else {
        this.ticketOrder.totalPrice = this.fullPrice;
        this.ticketOrder = new EventAttendeeTicketOrder(this.ticketOrder);
      }
    }
  }

  checkIfSoldOut(ticket: EventTicket) {
    return (
      !this.isBeforeStartSale(ticket) && ticket.getNumAvailableTickets() < 1
    );
  }

  isBeforeStartSale(ticket: EventTicket): boolean {
    if (ticket) {
      const today = new Date();

      if (!ticket.saleStartDate) {
        return false;
      }

      return ticket.saleStartDate ? today < ticket.saleStartDate : false;
    } else return false;
  }

  addTicket(ticket: EventTicket) {
    if (!ticket.id) {
      return;
    }

    const existingTicketIdx = this.orderTickets.findIndex(
      (orderTicket) => orderTicket.eventTicketId === ticket.id,
    );

    const currentOrder = this.orderTickets.find(
      (order) => order.eventTicketId === ticket.id,
    );

    if (
      this.voucherApplied &&
      (this.ticketsWithAppliedDiscount?.includes(ticket.id) ||
        this.ticketsWithAppliedDiscount?.length === 0)
    ) {
      if (
        (!currentOrder?.quantity || currentOrder?.quantity === 0) &&
        ticket.minAmountPerOrder
      ) {
        if (
          this.voucherApplied.limit &&
          (this.voucherApplied.limit >
            this.currentTicketQuantity + ticket.minAmountPerOrder ||
            this.voucherApplied.limit ===
              this.currentTicketQuantity + ticket.minAmountPerOrder)
        ) {
          this.currentTicketQuantity += ticket.minAmountPerOrder;
        } else {
          this.currentTicketQuantity++;
        }
      } else {
        this.currentTicketQuantity++;
      }
    }
    if (existingTicketIdx !== -1) {
      if (
        this.orderTickets[existingTicketIdx].quantity === 0 &&
        ticket.minAmountPerOrder
      ) {
        this.orderTickets[existingTicketIdx].quantity +=
          ticket.minAmountPerOrder;
      } else {
        this.orderTickets[existingTicketIdx].quantity += 1;
      }
    } else {
      this.orderTickets.push({
        eventTicketId: ticket.id,
        quantity: ticket.minAmountPerOrder ? ticket.minAmountPerOrder : 1,
        price: ticket.price,
      });
    }

    this.calculateFullPrice();
    if (this.isAddingFirstTime) {
      this.createTicketOrderSession();
      this.isAddingFirstTime = false;
    } else {
      this.updatedSessionRequest = this.createSessionRequest();
    }
  }

  removeTicket(ticket: EventTicket) {
    if (!ticket.id) {
      return;
    }

    const existingTicketIdx = this.orderTickets.findIndex(
      (orderTicket) => orderTicket.eventTicketId === ticket.id,
    );

    const currentOrder = this.orderTickets.find(
      (order) => order.eventTicketId === ticket.id,
    );

    if (existingTicketIdx !== -1) {
      if (
        currentOrder &&
        currentOrder.quantity &&
        ticket.minAmountPerOrder &&
        currentOrder.quantity === ticket.minAmountPerOrder
      ) {
        this.orderTickets[existingTicketIdx].quantity = 0;
        this.currentTicketQuantity =
          this.currentTicketQuantity - ticket.minAmountPerOrder;
      } else {
        if (this.orderTickets[existingTicketIdx].quantity > 1) {
          this.orderTickets[existingTicketIdx].quantity -= 1;
        } else {
          this.orderTickets.splice(existingTicketIdx, 1);
        }
        if (
          this.ticketsWithAppliedDiscount?.includes(ticket.id) ||
          (this.ticketsWithAppliedDiscount?.length === 0 && this.voucherApplied)
        ) {
          this.currentTicketQuantity--;
        }
      }
      this.calculateFullPrice();
      this.updatedSessionRequest = this.createSessionRequest();
    }
  }

  getTicketQuantity(ticketId: number) {
    const existingTicket = this.orderTickets.find(
      (orderTicket) => orderTicket.eventTicketId === ticketId,
    );

    const ticket = this.filteredTickets?.find((x) => x.id === ticketId);

    if (existingTicket) {
      return existingTicket.quantity;
    } else {
      return 0;
    }
  }

  private scrollToTop() {
    setTimeout(() => {
      const contentContainer =
        document.querySelector('.mat-mdc-dialog-surface') || window;
      // reset the view to the top on each step change
      if (contentContainer) {
        contentContainer.scrollTo(0, 0);
      }
    });
  }

  protected readonly EventType = EventType;
  protected readonly NumberUtils = NumberUtils;
  protected readonly close = close;
}
